import React from 'react'
import AOS from 'aos'
import { images } from './Utility/Images'
import { Row,Container,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BsFillArrowRightSquareFill } from 'react-icons/bs'
function FastoCaseStudy() {

  AOS.init();
  return (
    <>

    {/* breadcrumb-for-caseStudy */}

    <section className='breadcrumb-area'>
      <Container fluid>
        <Row>
          <Col xl={12}>
          <div className="breadcrumb-casestudy-box">
  <ul className="breadcrumb-casestudy-list">
    <li><Link to="/">Home </Link> <span>/</span> </li>
    <li><Link to="/case-studies">Case Study</Link> <span>/</span> </li>
    <li><Link to="#">Fasto App</Link> </li>
  </ul>
</div>
          </Col>
   
        </Row>
      </Container>
    </section>

        {/* case-study-banner-sec */}

        <section className="case-study-banner-sec" >
       <Container>
        <Row className='py-5'>
            <Col xl={4} className='case-study-banner-content' data-aos="fade-up" data-aos-duration="1000">
              <img src={images.fastologo} alt="" className='img-fluid' />
<h4 className='main-heading'>
Case Study for Fasto App
</h4>
  


<p className="desc">
Fasto: A Case Study of Subscription-based Grocery and Dairy Delivery Service
</p>
           
            </Col>

            <Col xl={8}>
              <div className="fasto-banner-img">
                <img src={images.fastobannerimg} alt="" className='img-fluid' />
              </div>
            </Col>
         
        </Row>
    </Container>

    </section>


{/* about-client-sec */}

<section className="about-client-sec py-40">

  <Container>

  <Row className='py-4'>

  <Col xl={6}>
  <div className="about-client-img">
    <img src={images.Casestudyabout} alt="" className='img-fluid' />
  </div>
  </Col>

    <Col xl={6} className='about-client-main-head'>
      <div className="about-client-content-box">
      <h3 className='main-heading1'>Introduction </h3>
        <p className="desc">
          Fasto is an innovative subscription-based online platform that aims to deliver fresh groceries, including dairy products, directly to consumers. With the convenience of technology and a commitment to freshness, Fasto combines the delivery model of Blinkit with the subscription-based model of Country Delight.
          Fast and reliable product delivery right to your doorstep. We ensure prompt and secure delivery of your items, so you can enjoy your purchase without any hassle.With the convenience of technology and a commitment to freshness, Fasto combines the delivery model of Blinkit with the subscription-based model of Country Delight.
         
          </p>
       
      </div>
    </Col>
   
  </Row>


  </Container>
</section>

{/* business-model-sec */}

<section className="business-model-sec py-40">
<Container>
<Row className='pb-3'>
<Col xl={10} className='business-model-main-head mx-auto'>
  <h3 className='main-heading'>Business Model </h3>
  <p className="desc">Fasto operates on a subscription-based model where customers can choose from various subscription plans. These plans range from basic (weekly delivery of essential groceries) to premium (daily delivery of groceries, dairy, and gourmet items).</p>
</Col>
    </Row>

    <Row className='pt-4'>

      <Col xl={4} md={4}  data-aos="fade-up" data-aos-duration="1000">

        <div className="business-model-card">
      
          <div className="business-model-list-box">
          <ul className="business-model-list">
            <li>  <div className="business-model-card-img">
       <img src={images.fastoorderplacement} alt="" className='img-fluid icon-b'/>  
       <img src={images.fastoorderplacementw} alt="" className='img-fluid icon-w'/>  
          </div></li>
          <li> <h5 className='card-head'>Order Placement</h5></li>
          </ul>
          </div>
          
            <p className="desc">Customers can place orders through the Fasto website or mobile application. They can choose to subscribe for daily, weekly, or monthly deliveries.</p>

            </div>
           

      </Col>

      <Col xl={4} md={4}  data-aos="fade-up" data-aos-duration="1000">

        <div className="business-model-card">
      
          <div className="business-model-list-box">
          <ul className="business-model-list">
            <li>  <div className="business-model-card-img">
       <img src={images.fastoproductrange} alt="" className='img-fluid icon-b'/>  
       <img src={images.fastoproductrangew} alt="" className='img-fluid icon-w'/>  
          </div></li>
          <li> <h5 className='card-head'>Product Range</h5></li>
          </ul>
          </div>
          
            <p className="desc">Fasto offers a wide range of products including fresh vegetables, fruits, staples, and dairy products. They emphasize sourcing from local farmers and dairy producers.</p>

            </div>
           

      </Col>

      <Col xl={4} md={4}  data-aos="fade-up" data-aos-duration="1000">

        <div className="business-model-card">
      
          <div className="business-model-list-box">
          <ul className="business-model-list">
            <li>  <div className="business-model-card-img">
       <img src={images.fastodeliverylogistics} alt="" className='img-fluid icon-b'/>  
       <img src={images.fastodeliverylogisticsw} alt="" className='img-fluid icon-w'/>  
          </div></li>
          <li> <h5 className='card-head'>Delivery Logistics</h5></li>
          </ul>
          </div>
          
            <p className="desc">
            Fasto has its own fleet of delivery vehicles that are equipped with refrigeration units to ensure that the products remain fresh during transit.
              </p>

            </div>
           

      </Col>

   
    </Row>
</Container>
</section>


{/* Market Research and Target Audience-sec */}

<section className="market-research-sec py-40">
<Container>
<Row className='pt-3 d-f-fd-cr'>

<Col xl={6} data-aos="fade-up" data-aos-duration="1000">
<div className="market-research-img">
  <img src={images.fastomarketresearch} alt="" className='img-fluid' />
</div>
</Col>

<Col xl={6} className='market-research-head mx-auto' data-aos="fade-up" data-aos-duration="1000">
  <h3 className='main-heading'>Market Research and Target Audience </h3>
  <p className="desc">Fasto operates on a subscription-based model where customers can choose from various subscription plans. These plans range from basic (weekly delivery of essential groceries) to premium (daily delivery of groceries, dairy, and gourmet items).</p>

  <div className="market-research-content-box">
  <Row>

<Col xl={6} md={6}>
<div className="market-research-content">
<span className='number-area'>01</span>
<h5>Target Market</h5>
<p className="para">Fasto initially targets urban areas where consumers are looking for convenient and healthier alternatives to supermarket shopping.</p>
</div>
</Col>

<Col xl={6} md={6}>
<div className="market-research-content">
<span className='number-area'>02</span>
<h5>Customer Personal</h5>
<p className="para">The typical customer is a working professional, aged between 25-50 years, who is health-conscious and prefers fresh, high-quality products but lacks the time for daily grocery shopping.</p>
</div>
</Col>

</Row>
  </div>

  




</Col>


    </Row>


  
</Container>
</section>


{/* Technology-sec */}

<section className="technology-sec py-40">
<Container>
  <Row className='py-3'>
  <Col xl={3} className='technology-head'>
  <h3 className='main-heading'>Technology </h3>
  <p className="desc">Fasto operates on a subscription-based model where customers can choose from various subscription plans. These plans range from basic (weekly delivery of essential groceries) to premium (daily delivery of groceries, dairy, and gourmet items).</p>
 
</Col>
<Col xl={9}>
<div className="technology-content-area">
  <Row>

    <Col xl={4}>
      <div className="technology-card">
<div className="technology-card-content">
  <div className="number-area">
01
  </div>
  <div className="content-txt">
    <h5>Web and Mobile App</h5>
    <p className="desc">Fasto develops a user-friendly web and mobile app that allows customers to easily manage their subscriptions, select products, and schedule deliveries.</p>
  </div>
</div>
      </div>
    </Col>

    <Col xl={4}>
      <div className="technology-card-style1">
<div className="technology-card-content">
  <div className="number-area">
02
  </div>
  <div className="content-txt">
    <h5>Data Analytics</h5>
    <p className="desc">The company utilizes data analytics to understand customer preferences, optimize inventory, and predict demand.
    </p>
  </div>
</div>
      </div>
    </Col>

    <Col xl={4}>
      <div className="technology-card">
<div className="technology-card-content">
  <div className="number-area">
03
  </div>
  <div className="content-txt">
    <h5>Supply Chain Optimization</h5>
    <p className="desc">Fasto invests in technology to optimize its supply chain and reduce the time between sourcing products and delivering them to the customer.
    </p>
  </div>
</div>
      </div>
    </Col>

  </Row>
</div>



</Col>
  </Row>

</Container>
</section>

{/* challenges and solutions-sec */}

<section className="challenges-and-solutions-sec py-40">
  <Container>
    <Row className='pb-3'>
      <Col xl={10} className='challenges-solutions-main-head mx-auto'>
      <h3 className='main-heading'>Challenges and Solutions </h3>
      </Col>
    </Row>
    <Row className='pt-3'>

      
    <Col xl={6}>
<div className="challenges-and-solutions-img">
<img src={images.fastochallengessolutions} alt="" className='img-fluid' />
</div>
      </Col>

    <Col xl={6}>
      <div className="challenges-solutions-content p-3">
        <Row>
          <div className="challenges-solutions-card">
            <div className="number-card">
              1.
            </div>
            <h6> Ensuring the freshness of products, especially dairy.</h6>
            <p className="desc"> Fasto establishes direct partnerships with local dairy farms and farmers to source products and utilizes refrigerated delivery vehicles.</p>
          </div>
        </Row>
        <Row>
          <div className="challenges-solutions-card">
          <div className="number-card">
              2.
            </div>
            <h6>Managing logistics for daily delivery.</h6>
            <p className="desc"> Fasto uses route optimization software and real-time tracking to ensure timely deliveries.</p>
          </div>
        </Row>
        <Row>
          <div className="challenges-solutions-card">
          <div className="number-card">
              3.
            </div>
            <h6> Convincing customers to switch to a subscription-based model.</h6>
            <p className="desc"> Fasto offers initial discounts, referral programs, and educates consumers on the benefits of fresh and locally sourced products.</p>
          </div>
        </Row>
      </div>


 
      </Col>

     
    </Row>

   
  </Container>
</section>


{/* results and growth */}

<section className="results-growth-sec py-40">
  <Container>
    <Row className='pb-3'>
      <Col xl={10} className='mx-auto results-growth-head'
      >
         <h3 className='main-heading'>Results and Growth </h3>
      </Col>
    </Row>
  <Row className='py-3'>
    <Col xl={6}>
      <div className="results-growth-img">
        <img src={images.fastoresultsgrowth} alt="" className='img-fluid' />
      </div>
    </Col>

<Col xl={6} className='results-growth-content'>
  <div className="results-growth-content-box">
    <ul className="results-growth-content-list">
      <li><BsFillArrowRightSquareFill className='checkicn'/>Fasto receives a positive response from the market due to its emphasis on freshness and convenience.</li>
      <li><BsFillArrowRightSquareFill className='checkicn'/>The subscription model helps in customer retention and ensures a steady revenue stream.</li>
      <li><BsFillArrowRightSquareFill className='checkicn'/>Data analytics help Fasto in optimizing its operations and increasing efficiency.</li>
      <li><BsFillArrowRightSquareFill className='checkicn'/>Partnerships with local producers lead to community development and sustainable sourcing practices.</li>

    </ul>
  </div>
</Col>

    </Row>
  </Container>
</section>

{/* future-prospects-sec */}

<section className="future-prospects-sec">
<Container>
    <Row>

    <Col xl={6} className='future-prospects-head'>

        <h3 className='main-heading'>Future Prospects </h3>
        <div className="future-prospects-content-box">
    <ul className="future-prospects-content-list">
      <li> <BsFillArrowRightSquareFill className='checkicn'/> Fasto plans to expand its services to more cities and diversify its product range.</li>
      <li><BsFillArrowRightSquareFill className='checkicn'/>The company is considering incorporating sustainability into its packaging and delivery processes.</li>
      <li><BsFillArrowRightSquareFill className='checkicn'/>Fasto is looking into partnerships with health and wellness experts to offer personalized dietary advice to subscribers.</li>
    

    </ul>
  </div>
       
      </Col>

      <Col xl={6}>
        <div className="future-prospects-img">
          <img src={images.fastofutureprospects} alt="" className='img-fluid' />
        </div>
      </Col>
   
    </Row>
</Container>
</section>


{/* conclusions */}

<section className="conclusion-sec py-40">
<Container>
<Row className='py-3'>
     
      <Col xl={10} className='conclusion-sec-head mx-auto' >
      
      <div className="conclusion-content-box">
      <h3 className='main-heading'>Conclusion : </h3>
          <p className="desc">Fasto's innovative approach to combining a subscription model with the delivery of fresh groceries and dairy products has proven to be successful</p>
         </div>
      </Col>
    </Row>
</Container>
</section>



    </>
  )
}

export default FastoCaseStudy