import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutimg from '../../../../assets/images/ui-ux/aboutus/aboutusimg.webp'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

function UiUxAboutus() {
  return (
   <>
    <section className={styles.digital_aboutus}>
        <Container>
          <Row className={styles.py_20}>
            <Col xl={6} lg={6}>
              <div className={styles.image}>
                <img
                  src={aboutimg}
               
                  alt=""
                />
              </div>
            </Col>

            <Col xl={6} lg={6} >
              <div className={styles.content}>
                <h3 className={styles.main_heading}>
                  {" "}
                  User Experience That Engages and Delights
                </h3>
                <p className={styles.desc}>
                  {" "}
                  We design the future—a future of immersive user experience, smooth navigation, and innovative design. As a UI UX design company, we design products that bring out the best in modern technology for large enterprises and startups.

                </p>

               <p className={styles.desc}>
               Our UI UX design services are designed to help you create functioning interfaces that meet your client's specific needs and demands. We approach each project with meticulous planning and agile development, ensuring that the result is a design that exceeds expectations.
               But it's not just about the technicalities – our experienced designers are dedicated to crafting visually stunning and intuitive experiences that engage and delight your users. With the help of our design system, we ensure that every element and interaction is thoughtful and deliberate. Whether you're looking to revamp your current product or create something entirely new, as one of the best UI UX design services in India, we have the skills and expertise to make it happen.
                </p>
              
                <p className={styles.buttn_holder}>
                  {" "}
                  <Link to="/contact-us" className={styles.rm_buttn}>
                    Explore More
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
   
   
   </>
  )
}

export default UiUxAboutus