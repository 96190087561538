import React, { useState } from 'react'
import styles from './styles.module.scss'

export default function ARApproach() {


    const [selectedTab, SetSelectedTab] = useState("ARandVR");







    return (
        <>
            <section className={styles.our_approach}>
                <div className="container">
                    <div className="row">
                        <h2 className={styles.main_heading}>
                            Our Approach
                        </h2>
                        <p className={styles.desc}>From strategy and design to development and launch, our team of experts will help you create the perfect digital product for your business needs.</p>
                    </div>
                    <div className={`row ${styles.pt_40}`}>
                        <div className="col-xl-3 col-lg-4 col-md-5">
                            <div className={styles.sidebar}>
                                <ul className={styles.list}>
                                    <li>
                                        <button className={selectedTab === "ARandVR" ? styles.tabactive : ""} onClick={() => SetSelectedTab("ARandVR")}>AR and VR Solutions</button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Retail" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Retail")}>Retail
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Education" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Education")}>Education and training
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Healthcare" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Healthcare")}>Healthcare
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "RealEstate" ? styles.tabactive : ""} onClick={() => SetSelectedTab("RealEstate")}>Real Estate
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Manufacturing" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Manufacturing")}>Manufacturing
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Media" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Media")}>Media and entertainment
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7">

                            {
                                selectedTab === "ARandVR" ? (
                                    <div className={styles.content}>
                                        <h5>Industry-specific AR and VR solutions</h5>
                                        <p>
                                            We have a team of experienced developers who are able to create and develop high-quality virtual worlds that can be used for a variety of purposes, including training, marketing, and entertainment.
                                        </p>
                                    </div>
                                ) : selectedTab === "Retail" ? (
                                    <div className={styles.content}>
                                        <h5>Retail</h5>
                                        <p>
                                        Virtual fitting rooms, In-store navigation, AR shop consultants, Product search and customization assistance.
                                        </p>
                                    </div>
                                ) : selectedTab === "Education" ? (

                                    <div className={styles.content}>
                                        <h5>Education and Training</h5>
                                        <p>
                                        Immersive learning, Distance learning, Inclusivity, Virtual onboarding.
                                        </p>
                                    </div>

                                ) : selectedTab === "Healthcare" ? (

                                    <div className={styles.content}>
                                        <h5>Healthcare</h5>
                                        <p>
                                        Doctor consultation, Augmented diagnosis, Surgery assistance, Training simulation.
                                        </p>
                                    </div>

                                ) : selectedTab === "RealEstate" ? (

                                    <div className={styles.content}>
                                        <h5>Real Estate</h5>
                                        <p>
                                        3D house tours, Home design and decorating apps, Creating architectural models.
                                        </p>
                                    </div>

                                ) : selectedTab === "Manufacturing" ? (
                                    <div className={styles.content}>
                                        <h5>Manufacturing</h5>
                                        <p>
                                        Design and engineering, Repair and diagnostics, Equipment installation, Safety training.
                                        </p>
                                    </div>

                                ) : selectedTab === "Media" ? (
                                    <div className={styles.content}>
                                        <h5>Media and Entertainment</h5>
                                        <p>
                                        Virtual theaters, museums, concerts, Gaming, VR events, Augmented journalism.
                                        </p>
                                    </div>
                                ) : (
                                    <div className={styles.content}>
                                        <h5>AR and VR</h5>
                                        <p>
                                            We have a team of experienced developers who are able to create and develop high-quality virtual worlds that can be used for a variety of purposes, including training, marketing, and entertainment.
                                        </p>
                                    </div>
                                )
                            }





                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
