import React, {  createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import './assets/scss/main.css';
// import { Toaster } from 'react-hot-toast';
// import ScrollToTop from './components/Utility/ScrollToTop';
// import Footer from './components/Layout/Footer'
// import Header from './components/Layout/Header'
// import Loader from "./components/Loader";
// import Index from "./components/Index";
// import FastoCaseStudy from "./components/FastoCaseStudy";
// import HuddleHeartCaseStudy from "./components/HuddleHeartCaseStudy";
// import PlywoodBazarCaseStudy from "./components/PlywoodBazarCaseStudy";
// import ROCareIndiaCaseStudy from "./components/ROCareIndiaCaseStudy";
// import NikkyPoreCaseStudy from "./components/NikkyPoreCaseStudy";
// import DeyorCaseStudy from "./components/DeyorCaseStudy";
// import SamagriCaseStudy from "./components/SamagriCaseStudy";
// import EbslonLandingPageUpdate from "./components/EbslonLandingPage/EbslonLandingPageUpdate";
// import CurrentLocation from "./components/LocationFinder/CurrentLocation";
// import { useContext } from "react";
import { useState } from "react";
import RoutesPage from "./Routes/RoutesPage";

export const Pagecontext = createContext("");


function App() {

  const [pageUrl, setPageUrl] = useState('');

  const url = window.location.href;


  return (
    <BrowserRouter>
      <Pagecontext.Provider value={[pageUrl, setPageUrl]}>

        <RoutesPage />




      </Pagecontext.Provider>




    </BrowserRouter >


  );
}

export default App;