
const routes = [
  "/",
  "/About",
  "/Blog",
  "/Contact",
  // "/educational-app-development",	 
  // "/manufacturing-software-development",	 
  // "/retail-POS-software",	 
  // "/custom-crm-development-services",	 
  // "/fintech-app-development-company",	 
  // "/grocery-app-development",	 
  // "/ecommerce-app-development",	 
  // "/healthcare-software-development",	 
  // "/job-portal-app-development",	 
  // "/travel-app-development",	 
  // "/real-estate-app-development",	 
  // "/custom-erp-software-development-company",	 
  // "/WhatsappBot",	 
  // "/society-management-app-development",	 
  // "/school-management-software-development-company",	 
  "/digital-marketing-agency",	 
  "/mobile-app-development-company",	 
  "/MarketplaceManagement",	 
  "/unlock-the-best-education-app-development-company-in-delhi-ncr",	 
  "/healthtech-solutions-custom-healthcare-software-and-app-development-company",	 
  "/an-ultimate-guide-to-new-product-development-for-e-commerce-platforms",	 
  "/finding-the-right-fintech-app-development-for-your-startup-hire-fintech-app-developer",	 
  "/top-10-features-for-society-management-apps-in-2024",	 
  "/benefits-of-hiring-a-job-portal-app-development-company",	 
  "/find-the-top-notch-custom-crm-development-company-delhi-india",	 
  "/best-digital-marketing-agency-to-increase-your-online-visibility-in-2024",	 
  "/choose-the-best-e-commerce-development-company-for-your-business",	 
  "/how-to-find-the-right-web-design-agency-for-your-website-development",	 
  "/which-is-the-best-mobile-app-development-company-in-delhi-ncr",	 
  "/choosing-the-best-website-development-company-for-your-business",	 
  "/how-to-secure-your-ecommerce-website-from-cyberattacks",	 
  "/the-ultimate-guide-to-developing-mobile-apps-in-2023",	 
  "/how-to-Choose-the-best-e-commerce-platform-for-your-business",	 
  "/how-the-new-ios-update-affects-your-app-performance",	 
  "/how-to-increase-your-e-commerce-conversion-rate-with-user",	 
  "/how-to-optimize-your-ecommerce-website-for-seo",	  
  "/Work",	 
  "/Technologies",	
  "/web-design-company-india",

  // "/",
  // "/About",
  // "/Services",
  // "/ServiceDetail",
  // "/Blog",
  // "/Contact",
  // "/TestimonialPartner",
  // "/ReturnRefund",
  // "/CaseStudy",
  // "/CaseStudyDetail",
  // "/CaseStudyDetail/creando",
  // "/Technologies",
  // "/WorkDetail",
  // "/Work",
  // "/Solutions",
  // "/SolutionsDetail",
  // "/how-to-secure-your-ecommerce-website-from-cyberattacks",
  // "/the-ultimate-guide-to-developing-mobile-apps-in-2023",
  // "/how-to-Choose-the-best-e-commerce-platform-for-your-business",
  // "/how-the-new-ios-update-affects-your-app-performance",
  // "/how-to-increase-your-e-commerce-conversion-rate-with-user",
  // "/choosing-the-best-website-development-company-for-your-business",
  // "/which-is-the-best-mobile-app-development-company-in-delhi-ncr",
  // "/how-to-find-the-right-web-design-agency-for-your-website-development",
  // "/how-to-optimize-your-ecommerce-website-for-seo",
  // "/choose-the-best-e-commerce-development-company-for-your-business",
  // "/best-digital-marketing-agency-to-increase-your-online-visibility-in-2024",
  // "/find-the-top-notch-custom-crm-development-company-delhi-india",
  // "/benefits-of-hiring-a-job-portal-app-development-company",
  // "/top-10-features-for-society-management-apps-in-2024",
  // "/finding-the-right-fintech-app-development-for-your-startup-hire-fintech-app-developer",
  // "/an-ultimate-guide-to-new-product-development-for-e-commerce-platforms",
  // "/healthtech-solutions-custom-healthcare-software-and-app-development-company",
  // "/unlock-the-best-education-app-development-company-in-delhi-ncr",
  // "/Custompages",
  // "/Technologiess",
  // "/educational-app-development",
  // "/ecommerce-app-development",
  // "/travel-app-development",
  // "/appdevelopment",
  // "/fintech-app-development-company",
  // "/job-portal-app-development",
  // "/grocery-app-development",
  // "/custom-erp-software-development-company",
  // "/healthcare-software-development",
  // "/custom-crm-development-services",
  // "/school-management-software-development-company",
  // "/society-management-app-development",
  // "/real-estate-app-development",
  // "/web-development-company",
  // "/digital-marketing-agency",
  // "/MarketplaceManagement",
  // "/website-designing-company",
  // "/manufacturing-software-development",
  // "/retail-POS-software",
  // "/WhatsappBot",
  // "/FastoCaseStudy",
  // "/HuddleHeartCaseStudy",
  // "/PlywoodBazarCaseStudy",
  // "/ROCareIndiaCaseStudy",
  // "/NikkyPoreCaseStudy",
  // "/DeyorCaseStudy",
  // "/SamagriCaseStudy",
  // "/TurningPointCaseStudy",
  // "/software-development-company",
  // "/appdevelopment",
  // "/mobile-app-development-company",
  // "/ContactForm",
  // "/ThankYouPage",
];

const baseUrl = "https://ebslon.com"; // Replace with your website's base URL
const sitemapFileName = "sitemap.xml"; // Name of the sitemap file

const getSitemap = () => {
  const sitemap = `
      <?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${routes
          .map(
            (route) => `
          <url>
            <loc>${baseUrl}${route}</loc>
            <lastmod>2023-11-01T10:41:09.342Z</lastmod>
              <priority>0.90</priority>
          </url>
        `
          )
          .join("")}
      </urlset>
    `;

  return sitemap;
};

export const generateSitemap = () => {
  const sitemapContent = getSitemap();

  // Save the sitemap to a file
  // fs.writeFileSync(sitemapFileName, sitemapContent);
  // const fileData = new Blob([sitemapContent], { type: "text/xml" })
  console.log(
    `Sitemap generated and saved as ${sitemapFileName}`,
    sitemapContent
  );
};
