import React from 'react'
import styles from './styles.module.scss'
import Logoimgblack from "../../../../assets/images/EbslonLandingPage/img/main-logo/black.webp";
import { Link } from 'react-router-dom'

export default function LandingHeader() {
  return (
    <>
     <div className={`${styles.header_box} sticky-top `}>
   <header className={` ${styles.main_header1}`}>
          <div className="container_fluid">
            <div className=" row align-items-center ">
              <div xl={3} lg={3} md={3} xs={5} className='col-xl-3 col-lg-3 col-md-3 col-5'>
                <Link to="https://ebslon.com/">
                  <div className={styles.main_logo}>
                    <img src={Logoimgblack} alt=""  />
                  </div>
                </Link>
              </div>
              <div className='col-xl-9 col-lg-9 col-md-9 col-7'>
                <div className={styles.header_contact_area}>
                  <Link
                    to="/contact-us"
                    className={styles.quote_buttn}
                    target="_blank"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
   </div>
    
    
    
    </>
  )
}
