import React, { useEffect, useState } from "react";
import "./Landingpage.css";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Logoimgblack from "../../assets/images/EbslonLandingPage/img/main-logo/black.webp";
import Ourofferingserp from "../../assets/images/EbslonLandingPage/img/our-offerings/erp.webp";
import Ourofferingshrms from "../../assets/images/EbslonLandingPage/img/our-offerings/hrms.webp";
import Ourofferingscrm from "../../assets/images/EbslonLandingPage/img/our-offerings/crm.webp";
import Ourofferingstravel from "../../assets/images/EbslonLandingPage/img/our-offerings/travelportal.webp";
import Ourofferingspos from "../../assets/images/EbslonLandingPage/img/our-offerings/pos.webp";
import Ourofferingsecommerce from "../../assets/images/EbslonLandingPage/img/our-offerings/ecommerce.webp";
import Ourofferingshealthcare from "../../assets/images/EbslonLandingPage/img/our-offerings/healthcare.webp";
import Ourofferingsedtech from "../../assets/images/EbslonLandingPage/img/our-offerings/edtech.webp";

import whatsappicon from "../../assets/images/EbslonLandingPage/images/ecommerce/whatsapp.webp";

import clientslogoahilya from "../../assets/images/EbslonLandingPage/img/clicents-logo/Aahilya.webp";
import clientslogoartisaga from "../../assets/images/EbslonLandingPage/img/clicents-logo/artisaga1.webp";
import clientslogotoneitup from "../../assets/images/EbslonLandingPage/img/clicents-logo/toneitup.webp";
import clientslogofasto from "../../assets/images/EbslonLandingPage/img/clicents-logo/fasto.webp";
import clientslogomeraki from "../../assets/images/EbslonLandingPage/img/clicents-logo/meraki.webp";
import clientslogonikkypore from "../../assets/images/EbslonLandingPage/img/clicents-logo/nikkypore1.webp";
import clientslogorocare from "../../assets/images/EbslonLandingPage/img/clicents-logo/rocare_india1.webp";
import clientslogoscentoria from "../../assets/images/EbslonLandingPage/img/clicents-logo/scentoria.webp";
import clientslogoceressy from "../../assets/images/EbslonLandingPage/img/clicents-logo/ceressy1.webp";
import clientslogochiratae from "../../assets/images/EbslonLandingPage/img/clicents-logo/chiratae.webp";
import clientslogocreek from "../../assets/images/EbslonLandingPage/img/clicents-logo/creek.webp";
import clientslogofavcy from "../../assets/images/EbslonLandingPage/img/clicents-logo/favcy1.webp";
// import clientslogofay from '../../assets/images/EbslonLandingPage/img/clicents-logo/fay.webp'
import clientslogohuddlehearts from "../../assets/images/EbslonLandingPage/img/clicents-logo/huddle-hearts.webp";
// import clientslogokimia from '../../assets/images/EbslonLandingPage/img/clicents-logo/kimia.webp'
import clientslogomarelli from "../../assets/images/EbslonLandingPage/img/clicents-logo/marelli.webp";
import clientslogomobius from "../../assets/images/EbslonLandingPage/img/clicents-logo/mobius.webp";
import clientslogomomporium from "../../assets/images/EbslonLandingPage/img/clicents-logo/momporium.webp";
import clientslogoparamount from "../../assets/images/EbslonLandingPage/img/clicents-logo/paramount.webp";
import clientslogopratham from "../../assets/images/EbslonLandingPage/img/clicents-logo/pratham.webp";
import clientslogostanum from "../../assets/images/EbslonLandingPage/img/clicents-logo/stanum.webp";
// import clientslogouttamchai from '../../assets/images/EbslonLandingPage/img/clicents-logo/uttam-chai.webp'
import clientslogowoo from "../../assets/images/EbslonLandingPage/img/clicents-logo/woo.webp";

import clientslogoshringaaar from "../../assets/images/EbslonLandingPage/img/clicents-logo/shringaaar.webp";
import clientslogosundaysforever from "../../assets/images/EbslonLandingPage/img/clicents-logo/sundaysforever1.webp";
import clientslogovoiceoc from "../../assets/images/EbslonLandingPage/img/clicents-logo/voiceoc.webp";
// import clientreviewsleftquote from '../../assets/images/EbslonLandingPage/img/our-client-reviews/left.webp'
import contactusbannerimg from "../../assets/images/EbslonLandingPage/img/contact-us-banner/contact-us-img.webp";

// import whychooseusmainimg from '../../assets/images/EbslonLandingPage/img/why-choose-us1/whychooseimage.webp'
import whychooseuscustomer from "../../assets/images/EbslonLandingPage/img/why-choose-us1/satisfaction.webp";
import whychooseusintegration from "../../assets/images/EbslonLandingPage/img/why-choose-us1/integration.webp";
import whychooseusflexible from "../../assets/images/EbslonLandingPage/img/why-choose-us1/flexible.webp";
import whychooseuscustomersupport from "../../assets/images/EbslonLandingPage/img/why-choose-us1/customer-service.webp";
import whychooseus360deg from "../../assets/images/EbslonLandingPage/img/why-choose-us1/360-view.webp";
import whychooseuscompetitveprice from "../../assets/images/EbslonLandingPage/img/why-choose-us1/compeitiveprice.webp";
// import clientreviewsimg1 from '../../assets/images/EbslonLandingPage/img/our-client-reviews/dummy.webp'
import technologiesimg1 from "../../assets/images/EbslonLandingPage/img/technologies/android.svg";
import technologiesimg2 from "../../assets/images/EbslonLandingPage/img/technologies/angular.svg";
import technologiesimg3 from "../../assets/images/EbslonLandingPage/img/technologies/css.svg";
import technologiesimg4 from "../../assets/images/EbslonLandingPage/img/technologies/experessjs.webp";
import technologiesimg5 from "../../assets/images/EbslonLandingPage/img/technologies/html.svg";
import technologiesimg6 from "../../assets/images/EbslonLandingPage/img/technologies/flutter.webp";
import technologiesimg7 from "../../assets/images/EbslonLandingPage/img/technologies/mongo.webp";
import technologiesimg8 from "../../assets/images/EbslonLandingPage/img/technologies/mysql.svg";
import technologiesimg9 from "../../assets/images/EbslonLandingPage/img/technologies/nodejs.svg";
import technologiesimg10 from "../../assets/images/EbslonLandingPage/img/technologies/php.svg";
import technologiesimg11 from "../../assets/images/EbslonLandingPage/img/technologies/vuejs.svg";
import technologiesimg12 from "../../assets/images/EbslonLandingPage/img/technologies/reactnavtive.webp";
import footeruxui from "../../assets/images/EbslonLandingPage/img/footerbg/ux-ui.webp";
import footerweb from "../../assets/images/EbslonLandingPage/img/footerbg/web-development.webp";
import footerapp from "../../assets/images/EbslonLandingPage/img/footerbg/app-development.webp";
import footerdigital from "../../assets/images/EbslonLandingPage/img/footerbg/digital-marketing.webp";
import footerchatbot from "../../assets/images/EbslonLandingPage/img/footerbg/chat-bot.webp";

import { Link, useNavigate } from "react-router-dom";

import { ImArrowUpRight2 } from "react-icons/im";
import { AiFillStar } from "react-icons/ai";
import portfolioAnillo from "../../assets/images/EbslonLandingPage/img/portfolio/Anillo.webp";
import portfolioAahilya from "../../assets/images/EbslonLandingPage/img/portfolio/Aahilya.webp";
import portfolioArtisaga from "../../assets/images/EbslonLandingPage/img/portfolio/Artisaga.webp";
import portfolioBelmonk from "../../assets/images/EbslonLandingPage/img/portfolio/Belmonk.webp";
import portfolioMeraki from "../../assets/images/EbslonLandingPage/img/portfolio/Meraki.webp";

import portfolioScentoria from "../../assets/images/EbslonLandingPage/img/portfolio/Scentoria.webp";
import portfoliotoneitup from "../../assets/images/EbslonLandingPage/img/portfolio/toneitup.webp";
import portfolioSabkaAddress from "../../assets/images/EbslonLandingPage/img/portfolio/SabkaAddress.webp";
import portfolioDSR from "../../assets/images/EbslonLandingPage/img/portfolio/DSR.webp";
import portfoliofasto from "../../assets/images/EbslonLandingPage/img/portfolio/fasto.webp";
import portfolioModernMart from "../../assets/images/EbslonLandingPage/img/portfolio/Modernmart.webp";
import portfolioNikkypore from "../../assets/images/EbslonLandingPage/img/portfolio/Nikkypore.webp";
import portfolioplywoodbazar from "../../assets/images/EbslonLandingPage/img/portfolio/plywoodbazar.webp";
import portfoliorocare from "../../assets/images/EbslonLandingPage/img/portfolio/Rocare.webp";
import portfoliosamagri from "../../assets/images/EbslonLandingPage/img/portfolio/samagri.webp";
import portfolioturningpoint from "../../assets/images/EbslonLandingPage/img/portfolio/Turningpoint.webp";

import brochure from "../../assets/images/EbslonLandingPage/img/modal/brochure.png";

import { InputGroup, Form, Modal } from "react-bootstrap";
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
// import 'react-phone-number-input/style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";
import AOS from "aos";

import {
  BrochureDownloadApi,
  addLandingPage,
} from "../../services/landingPage.service";
import { toastError, toastSuccess } from "../../utils/toast";
import { Helmet } from "react-helmet";
// import { useRef } from "react";

function EbslonLandingPageUpdate() {
  // const url = window.location.to;

  const navigate = useNavigate();

  const navigateToThankYouPage = () => {
    // 👇️ navigate to /ThankYouPage

    navigate("/ThankYouPage");
  };

  AOS.init();

  // const { push } = useRouteError();
  // const Router = useRouteError();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [solution, setSolution] = useState("");

  const [firstName2, setFirstName2] = useState("");
  const [lastName2, setLastName2] = useState("");
  const [email2, setEmail2] = useState("");
  const [phone2, setPhone2] = useState("");
  const [message2, setMessage2] = useState("");
  const [companyName2, setCompanyName2] = useState("");
  const [solution2, setSolution2] = useState("");

  const [name3, setName3] = useState("");
  const [email3, setEmail3] = useState("");
  const [phone3, setPhone3] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalShow, setModalShow] = React.useState(false);

  // AOS.init();
  useEffect(() => {
    AOS.init();

    // let modaltrue = localStorage.getItem("model");
    // if (!modaltrue) {
    //   setTimeout(() => {
    //     if (window) {
    //       if (window.innerWidth >= 1200) {
    //         setModalShow(true);
    //         localStorage.setItem("model", "true");
    //       }
    //     }
    //   }, 3000);
    // }
  }, []);

  const [showWhatsappOnLeft, setShowWhatsappOnLeft] = useState(false);

  useEffect(() => {
    if (navigator) {
      console.log(navigator.userAgent, "useragent");
      if (
        navigator.userAgent.toLowerCase().includes("iphone") ||
        navigator.userAgent.toLowerCase().includes("android")
      ) {
        setShowWhatsappOnLeft(false);
      } else {
        setShowWhatsappOnLeft(true);
      }
    }
  }, []);

  const handleSubmit = async () => {
    try {
      if (firstName == "") {
        toastError("Name is mandatory !!!");
        return;
      }

      if (email == "") {
        toastError("Email is mandatory !!!");
        return;
      }
      if (email && !(email.includes("@") && email.includes("."))) {
        toastError("Invalid Email provided !!!");
        return;
      }
      if (phone == "") {
        toastError("Phone Number is mandatory !!!");
        return;
      }
      if (phone && phone.length !== 10) {
        toastError(
          "Invalid Phone Number provided , phone number must be 10 digits !!!"
        );
        return;
      }

      let obj = {
        firstName,
        lastName,
        email,
        phone,
        message,
        solution,
        companyName,
        page: window.location.pathname,
      };

      let { data: res } = await addLandingPage(obj);

      if (res.message) {
        toastSuccess(res.message);
        // push("/ThankYouPage");
      }

      navigate("/ThankYouPage");
    } catch (err) {
      toastError(err);
    }
  };

  const handleSubmit2 = async () => {
    try {
      if (firstName2 == "") {
        toastError("First Name is mandatory !!!");
        return;
      }

      if (email2 == "") {
        toastError("Email is mandatory !!!");
        return;
      }
      if (email2 && !(email2.includes("@") && email2.includes("."))) {
        toastError("Invalid Email provided !!!");
        return;
      }
      if (phone2 == "") {
        toastError("Phone Number is mandatory !!!");
        return;
      }
      if (phone2 && phone2.length !== 10) {
        toastError(
          "Invalid Phone Number provided , phone number must be 10 digits !!!"
        );
        return;
      }

      let obj = {
        firstName: firstName2,
        lastName: lastName2,
        email: email2,
        phone: phone2,
        message: message2,
        companyName: companyName2,
        solution: solution2,
        page: window.location.pathname,
      };

      let { data: res } = await addLandingPage(obj);

      if (res.message) {
        toastSuccess(res.message);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const HandleSubmitBrochureEnquiry = async () => {
    try {
      let regex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$");
      let Phoneregex = new RegExp("^[7-9][0-9]{9}$");

      if (name3 == "") {
        toastError("Name is mandatory !!!");
        return;
      }
      if (email3 == "") {
        toastError("Email is mandatory !!!");
        return;
      }
      if (regex.test(email3) == false) {
        toastError("Invalid Email provided !!!");
        return;
      }
      if (phone3 == "") {
        toastError("Phone number is mandatory !!!");
        return;
      }
      if (Phoneregex.test(phone3) == false) {
        toastError("Invalid Phone number provided !!!");
        return;
      }

      let obj = {
        name: name3,
        email: email3,
        phone: phone3,
      };
      console.log(obj);
      let { data: res } = await BrochureDownloadApi(obj);
      if (res.message) {
        setName3("");
        setEmail3("");
        setPhone3("");
        handleClose();
        toastSuccess(res.message);
        window.open(
          "https://ebslon.com/api/Ebslon.pdf",
          "_blank" // <- This is what makes it open in a new window.
        );

        // alert(res.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ebslon.com/software-development-company"
        />
        <title>Software development services - Ebslon Infotech</title>
        <meta
          name="description"
          content="Software development services for 30+ industries • Software application planning, design, coding, testing, support, evolution • Quality-first. "
        />
      </Helmet>
      {/* main-banner-sec */}

      <section className="main-banner-sec1 fluid-padding py-40">
        <header className="main-header1 fluid-padding">
          <Container fluid>
            <Row className="a-i-c ">
              <Col xl={3} lg={3} md={3} xs={5}>
                <a href="https://ebslon.com/" target="_blank">
                  <div className="main-logo1">
                    <img src={Logoimgblack} alt="" width={100} height={45} />
                  </div>
                </a>
              </Col>
              <Col xl={9} lg={9} md={9} xs={7}>
                <div className="header-contact-area">
                  <Link
                    to="/ContactForm"
                    className="req-quote-buttn1"
                    target="_blank"
                  >
                    Get Quote
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <Container fluid>
          <Row className=" d-f-j-c-sb mt-5-percent j-c-sa">
            <Col xl={7} md={6}>
              <div className="main-banner-content1">
                <h1 className="main-heading">
                  <Link
                    to="https://api.whatsapp.com/message/5X3JK7P5ARXAD1?autoload=1&app_absent=0"
                    target="_blank"
                  >
                    Custom <span> Software</span>
                    <a className=""> Development</a> Company{" "}
                  </Link>
                </h1>
                <p className="desc">
                  Ebslon Infotech stands out as the premier Mobile app
                  development company in Delhi. specializing in delivering
                  top-tier iOS and web app development services. Our commitment
                  to excellence ensures that clients receive tailor-made mobile
                  app solutions that redefine standards in the industry. Choose{" "}
                  <Link to="/" className="yellow_b">
                    {" "}
                    Ebslon Infotech{" "}
                  </Link>{" "}
                  for unparalleled expertise in crafting custom mobile
                  applications. With a dedicated focus on quality, innovation,
                  and customer satisfaction, we are your ultimate destination
                  for the best in app development. Experience cutting-edge
                  mobile application solutions and witness why we're recognized
                  as the best mobile app development company, setting new
                  benchmarks in the field.
                </p>
                <p className="mt-35">
                  <Link
                    to="/ContactForm"
                    className="quick-enquiry-buttn1"
                    target="_blank"
                  >
                    Get Free Consultation{" "}
                    <span>
                      <ImArrowUpRight2 />
                    </span>
                  </Link>
                </p>
              </div>
            </Col>

            <Col xl={4} md={5}>
              <div className="contact-form-box1">
                <h4 className="form-head">Request a callback</h4>
                {/* <p className="desc">Experience a Complete 360° Marketing Solution For Your Business</p> */}
                <div className="contact-form-body mt-3">
                  <div className="row">
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Name"
                          aria-label="Username"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Phone Number"
                          aria-label="Username"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Email"
                          aria-label="Username"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Company Name"
                          // aria-label="Username"
                          // aria-describedby="basic-addon1"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12 footer-form mb-3">
                      <Form.Select
                        value={solution}
                        onChange={(e) => setSolution(e.target.value)}
                      >
                        <option>Select Solution</option>
                        <option>CRM</option>
                        <option> ERP</option>
                        <option>Edtech</option>
                        <option> Ecommerce</option>
                        <option>Hrms</option>
                        <option>Healthcare</option>
                        <option>POS</option>
                        <option>Travel Portal</option>
                        <option value="others">Others</option>
                      </Form.Select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        style={{ minHeight: "100px" }}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        className="mb-3 message-textarea"
                      />
                    </div>
                  </div>

                  <div className="t-c">
                    <div
                      onClick={() => handleSubmit()}
                      // {navigateToThankYouPage}
                      className="enquiry-now-buttn"
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* our-clients */}
      <section className="our-clients-sec fluid-padding">
        <Container fluid>
          <Row className=" our-clients-sec-wrapper">
            <Swiper
              slidesPerView={6}
              spaceBetween={20}
              loop={true}
              speed={700}
              autoplay={true}
              modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 2,

                  spaceBetween: 20,
                },

                640: {
                  slidesPerView: 4,

                  spaceBetween: 20,
                },

                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },

                1024: {
                  slidesPerView: 6,

                  spaceBetween: 20,
                },
              }}
              className="mySwiper py-20"
            >
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogoahilya} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogoartisaga}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogotoneitup}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogofasto} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogomeraki} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogonikkypore}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogorocare} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogoscentoria}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogoceressy}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogochiratae}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogofavcy} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogoshringaaar}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogowoo} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogosundaysforever}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogocreek} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogovoiceoc}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogohuddlehearts}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogomarelli}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogomobius} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogomomporium}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogoparamount}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    src={clientslogopratham}
                    alt=""
                    width={150}
                    height={70}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="our-clients-logo-img"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img src={clientslogostanum} alt="" width={150} height={70} />
                </div>
              </SwiperSlide>
            </Swiper>
          </Row>
        </Container>
      </section>

      {/* our-offerings */}

      <section className="our-offerings-sec fluid-padding py-40">
        <Container fluid>
          <Row className="pb-3">
            <Col xl={10} className="mx-auto">
              <div className="our-offerings-main-head">
                <h2 className="main-heading">
                  Choose The Best <span> Software Development Company</span> For
                  Your Project!
                </h2>
                <p className="desc">
                  Ebslon Infotech proudly leads the way as the foremost{" "}
                  <Link
                    to="/which-is-the-best-mobile-app-development-company-in-delhi-ncr"
                    className="yellow_b"
                  >
                    {" "}
                    Mobile App Development Company in Delhi{" "}
                  </Link>{" "}
                  . Our expertise lies in crafting custom mobile app development
                  and web app development services, among other specialties, to
                  create distinctive digital experiences finely tuned for your
                  business triumph. Immerse yourself in excellence through our
                  dedicated app development services, meticulously designed to
                  elevate your brand. As the{" "}
                  <strong>Best Mobile App Development Company</strong> , we're
                  committed to delivering superior mobile application solutions
                  that redefine industry standards and drive your success
                  forward.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={10} className="mx-auto">
              <Row className="pt-3">
                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card brder-r">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingscrm}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>CRM</h5>
                      <p className="desc">
                        As an enterprise software development company, we help
                        you manage customer relationships effectively with our
                        CRM solutions.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card brder-r">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingserp}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>ERP</h5>
                      <p className="desc">
                        As a specialized ERP software development company, we
                        deliver business process optimization for superior
                        management efficiency.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card brder-r">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingsedtech}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>Edtech</h5>
                      <p className="desc">
                        Experience bespoke EdTech solutions developed by our
                        team, recognized as one of the best software development
                        companies in the education domain.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card ">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingsecommerce}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>Ecommerce</h5>
                      <p className="desc">
                        Our status as a top-tier software product development
                        company ensures elevated online sales with our tailored
                        Ecommerce solutions.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card brder-t brder-r">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingshrms}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>HRMS</h5>
                      <p className="desc">
                        Streamline your human resource management with our HRMS
                        solutions, crafted by a top software development company
                        for increased operational efficiency.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card brder-t brder-r">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingshealthcare}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>Healthcare</h5>
                      <p className="desc">
                        {" "}
                        As a leading software development company, we deliver
                        cutting-edge healthcare solutions, improving patient
                        care and streamlining health services.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card brder-t brder-r">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingspos}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>POS</h5>
                      <p className="desc">
                        Our POS solutions revolutionize retail, integrating
                        sales and customer management, a signature service of
                        our enterprise software development.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} className="padding-zeroo">
                  <div className="our-offerings-card brder-t">
                    <div className="our-offerings-card-icon">
                      <img
                        src={Ourofferingstravel}
                        alt=""
                        width={64}
                        height={64}
                      />
                    </div>

                    <div className="our-offerings-card-content">
                      <h5>Travel Portal</h5>
                      <p className="desc">
                        Expand your travel business with our intuitive Travel
                        Portal solutions, crafted with expertise in SaaS
                        development.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* portfolio-sec */}
      <section className="portfolio-sec1 fluid-padding py-40">
        <Container fluid>
          <Row className="pb-3">
            <Col xl={12} className="mx-auto">
              <div className="portfolio-sec-main-head">
                <h2
                  className="main-heading"
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  A Glimpse of <span>Our Work</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xl={10} className="mx-auto">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                speed={3000}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={7} lg={7} md={7}>
                      <div className="portfolio-img" data-aos="flip-up">
                        <img
                          src={portfolioAnillo}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content" data-aos="flip-up">
                        <h3 className="heading">
                          <span> Anillo</span>{" "}
                        </h3>
                        <p className="desc">
                          Showcasing our tailor-made eCommerce solution for a
                          remarkable team of jewelers, who turn dreams into
                          reality.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={7} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioMeraki}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Meeraki</span>
                        </h3>
                        <p className="desc">
                          Meraki is a non-toxic holistic hair care brand. Our
                          products are inspired by the practice of ancient
                          crystal healing.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={7} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfoliotoneitup}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Toneitup</span>{" "}
                        </h3>
                        <p className="desc">
                          Tone It Up’s nutrition line is made for women, by
                          women.Tone It Up nutrition products are dairy-free,
                          gluten-free, and never made with sugar alcohols. TIU
                          is the top selling protein at Target nationwide!
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={7} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioAahilya}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Aahilya</span>{" "}
                        </h3>
                        <p className="desc">
                          We started Aahilya to help provide a source of income
                          for the rural craftspeople who have been effected by
                          the COVID-19 Virus and lockdown. We want to encourage
                          people to buy the handmade
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={7} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioArtisaga}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Artisaga</span>{" "}
                        </h3>
                        <p className="desc">
                          ARTISAGA is a design and sourcing platform enabling
                          small and medium businesses present globally to source
                          wholesale artisanal and consciously made Home & Living
                          goods.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={7} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioBelmonk}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Belmonk</span>{" "}
                        </h3>
                        <p className="desc">
                          Belmonk is ready-to-wear brand with an experience
                          surpassing two generations believes in creating
                          versatile yet powerful apparels that offers the best
                          selection of Quirky.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={7} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioScentoria}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Scentoria</span>
                        </h3>
                        <p className="desc">
                          Scentoria, a renowned fragrance platform. We crafted
                          their user-friendly and immersive website, enhancing
                          the experience for perfume connoisseurs worldwide.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioDSR}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> DSR</span>
                        </h3>
                        <p className="desc">
                          Enhance your style with our exquisite collection of
                          watches. From elegant timepieces to sporty designs,
                          find the perfect watch to complement your personality
                          and elevate your fashion statement.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioModernMart}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Morden mart</span>
                        </h3>
                        <p className="desc">
                          Discover a world of endless shopping possibilities on
                          our e-commerce site. Explore a vast range of products,
                          enjoy seamless browsing, and indulge in convenient
                          online shopping from the comfort of your home.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfoliofasto}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Fasto</span>
                        </h3>
                        <p className="desc">
                          Fast and reliable product delivery right to your
                          doorstep. We ensure prompt and secure delivery of your
                          items, so you can enjoy your purchase without any
                          hassle.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioplywoodbazar}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span>Plywood Bazar</span>
                        </h3>
                        <p className="desc">
                          Premium plywood for all your construction and
                          woodworking needs. Choose from a variety of grades and
                          thicknesses. Experience the quality difference today!
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfoliorocare}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> RO Care India</span>
                        </h3>
                        <p className="desc">
                          Professional water purifier service for clean and
                          healthy drinking water. Our experts will ensure your
                          water purifier is properly maintained and serviced to
                          keep your family safe and hydrated.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioNikkypore}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span>Nikki Pore</span>
                        </h3>
                        <p className="desc">
                          Wide range of filters available for various
                          applications. From water filters to air filters, we
                          have the right solution to meet your needs. Ensure
                          clean and pure air and water with our high-quality
                          filter options.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfoliosamagri}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span>Samagri</span>
                        </h3>
                        <p className="desc">
                          Complete your religious rituals with our premium pooja
                          samagri. From incense sticks to sacred oils, we offer
                          a wide selection of authentic products to enhance your
                          spiritual practices.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioturningpoint}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Turning Point</span>
                        </h3>
                        <p className="desc">
                          Transform your space with our exquisite furniture
                          collection. From elegant sofas to stylish tables, we
                          offer a wide range of high-quality furniture that
                          combines comfort, functionality, and aesthetic appeal.
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="a-i-c">
                    <Col xl={6} lg={7} md={7}>
                      <div className="portfolio-img">
                        <img
                          src={portfolioSabkaAddress}
                          alt=""
                          width={500}
                          height={300}
                        />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className="portfolio-content">
                        <h3 className="heading">
                          <span> Sabka Address</span>
                        </h3>
                        <p className="desc">
                          Never forget a location again with our address-saving
                          app. Easily save and organize addresses of your
                          favorite stores, restaurants, and more. Effortlessly
                          access directions whenever you need them, making
                          navigation a breeze. Stay organized and always find
                          your way!
                        </p>
                        <p className="mt-25">
                          <Link
                            to="/ContactForm"
                            className="req-for-demo-buttn"
                            target="_blank"
                          >
                            Request For Demo
                          </Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>

      {/* technologies-sec */}

      <section className="technologies-sec fluid-padding py-40">
        <Container fluid>
          <Row>
            <Col xl={10} className="mx-auto">
              <Row className="j-c-sb">
                <Col xl={4}>
                  <div className="technologies-sec-main-head">
                    <h2 className="main-heading">
                      {" "}
                      <span>Technologies </span> and Platforms
                    </h2>
                    <p className="sub-heading">
                      Empowering Businesses with Advanced Technology
                    </p>
                    <p className="desc">
                      As an enterprise software development company, we stride
                      in tune with emerging technologies. We employ the latest
                      software and technology platforms to deliver advanced,
                      high-performance solutions tailored to your business
                      needs.
                    </p>
                  </div>
                </Col>
                <Col xl={7} className="mx-auto">
                  <Row className="pt-3">
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg1}
                          alt=""
                          width={100}
                          height={50}
                        />
                        {/* <h6>Android</h6> */}
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg2}
                          alt=""
                          width={100}
                          height={50}
                        />
                        {/* <h6>Angular</h6> */}
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg3}
                          alt=""
                          width={100}
                          height={50}
                        />
                        {/* <h6>Express</h6> */}
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg4}
                          alt=""
                          width={100}
                          height={50}
                        />
                        {/* <h6>Android</h6> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg5}
                          alt=""
                          width={100}
                          height={50}
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg6}
                          alt=""
                          width={100}
                          height={50}
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg7}
                          alt=""
                          width={100}
                          height={50}
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-b-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg8}
                          alt=""
                          width={100}
                          height={50}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg9}
                          alt=""
                          width={100}
                          height={50}
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg10}
                          alt=""
                          width={100}
                          height={50}
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div
                        className="technologies-card brder-r-tech"
                        data-aos="fade-up"
                      >
                        <img
                          src={technologiesimg11}
                          alt=""
                          width={100}
                          height={50}
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={3} xs={6} className="padding-zeroo">
                      <div className="technologies-card" data-aos="fade-up">
                        <img src={technologiesimg12} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* contact-us-banner */}

      <section className="contact-us-banner-sec fluid-padding py-40">
        <Container fluid>
          <Row className="align-items-center">
            <Col xl={3}>
              <div className="contact-us-banner-img">
                <img src={contactusbannerimg} alt="" />
              </div>
            </Col>
            <Col xl={6}>
              <div className="contact-us-banner-content">
                <h3 className="main-heading">
                  Want to develop a Software & Not Sure How to Get Started?
                </h3>
                <p className="desc">
                  Our Team has rich industry experience to develop enterprise
                  software's
                </p>
              </div>
            </Col>
            <Col xl={3} className="contact-us-banner-button">
              <Link
                to="/ContactForm"
                className="lets-discuss-button"
                target="_blank"
              >
                Let's Discuss Now
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      {/* why-choose-us */}

      <section className="why-choose-us-sec1 fluid-padding py-40">
        <Container fluid>
          <Row className="pb-3 j-c-sb">
            <Col xl={4}>
              <div className="why-choose-us-main-head">
                <h2 className="main-heading">
                  Why <span>Choose us?</span>{" "}
                </h2>
                <p className="sub-heading">
                  Experience unparalleled dedication, expertise, and results
                  with our software development services.
                </p>
                <p className="desc">
                  Partner with Ebslon Infotech, a leading{" "}
                  <strong>Top Mobile App Development Company </strong> . We
                  create customized iOS and web apps for your success! Trusted{" "}
                  <strong>Mobile App Developers in Delhi</strong> , offering
                  top-notch mobile application solutions.
                </p>
                <p>
                  <Link
                    className="Lets-talk-buttn"
                    to="/ContactForm"
                    target="_blank"
                  >
                    Let's Talk
                  </Link>
                </p>
              </div>
            </Col>
            <Col xl={7}>
              <div className="why-choose-us-main-content">
                <Row>
                  <Col xl={4} md={4}>
                    <div
                      className="why-choose-us-content-card"
                      data-aos="fade-down"
                    >
                      <div className="why-choose-us-card-img">
                        <img
                          src={whychooseuscustomer}
                          alt=""
                          width={67}
                          height={67}
                        />
                      </div>
                      <h5>Customer Satisfaction</h5>
                    </div>
                  </Col>
                  <Col xl={4} md={4}>
                    <div
                      className="why-choose-us-content-card"
                      data-aos="fade-down"
                    >
                      <div className="why-choose-us-card-img">
                        <img
                          src={whychooseusintegration}
                          alt=""
                          width={67}
                          height={67}
                        />
                      </div>
                      <h5>Integrity & Transparency</h5>
                    </div>
                  </Col>
                  <Col xl={4} md={4}>
                    <div
                      className="why-choose-us-content-card"
                      data-aos="fade-down"
                    >
                      <div className="why-choose-us-card-img">
                        <img
                          src={whychooseuscustomersupport}
                          alt=""
                          width={67}
                          height={67}
                        />
                      </div>
                      <h5>24/7 Customer Support</h5>
                    </div>
                  </Col>
                  <Col xl={4} md={4}>
                    <div
                      className="why-choose-us-content-card"
                      data-aos="fade-down"
                    >
                      <div className="why-choose-us-card-img">
                        <img
                          src={whychooseusflexible}
                          alt=""
                          width={67}
                          height={67}
                        />
                      </div>
                      <h5>Flexible Engagement Models</h5>
                    </div>
                  </Col>
                  <Col xl={4} md={4}>
                    <div
                      className="why-choose-us-content-card"
                      data-aos="fade-down"
                    >
                      <div className="why-choose-us-card-img">
                        <img
                          src={whychooseus360deg}
                          alt=""
                          width={67}
                          height={67}
                        />
                      </div>
                      <h5>360-Degree Solutions</h5>
                    </div>
                  </Col>
                  <Col xl={4} md={4}>
                    <div
                      className="why-choose-us-content-card"
                      data-aos="fade-down"
                    >
                      <div className="why-choose-us-card-img">
                        <img
                          src={whychooseuscompetitveprice}
                          alt=""
                          width={67}
                          height={67}
                        />
                      </div>
                      <h5>Competitive Pricing & Delivery</h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* our-client-reviews */}

      <section className="our-client-reviews fluid-padding ">
        <Container fluid>
          <Row>
            <Col xl={10} className="mx-auto">
              <div className="our-client-reviews-main-head">
                <h2 className="main-heading">
                  What <span> Our Clients</span> Say
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="pb-3">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              speed={3000}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 1,

                  spaceBetween: 20,
                },

                640: {
                  slidesPerView: 2,

                  spaceBetween: 20,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },

                1024: {
                  slidesPerView: 3,

                  spaceBetween: 20,
                },
              }}
              className="mySwiper py-25"
            >
              <SwiperSlide>
                <div className="our-client-reviews-card">
                  <div className="our-client-reviews-content">
                    <p className="desc">
                      Thats my app completely designed and launched by Ebslon .
                      Work of each phase was before time . The team is a bunch
                      of complete enthusiasts and would do everything to make
                      the product stand out in competition.
                    </p>
                  </div>
                  <ul className="our-client-reviews-list">
                    {/* <li>
                      <div className="client-details">
                        <img src={clientreviewsimg1} alt='' className='img-fluid'>
                      </div>
                    </li> */}
                    <li>
                      <ul className="client-reviews-inner-list">
                        <li>
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </li>
                        <li>
                          {" "}
                          <span className="person-name">Zainul Khatani</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="our-client-reviews-card">
                  <div className="our-client-reviews-content">
                    <p className="desc">
                      Ebslon Infotech is one of the best company for developing
                      an ecommerce platform. I got the delivery of my other
                      website 3 months back and everything is working so smooth.
                      The website is loading faster and working really well.{" "}
                    </p>
                  </div>
                  <ul className="our-client-reviews-list">
                    {/* <li>
                      <div className="client-details">
                        <img src={clientreviewsimg1} alt='' className='img-fluid'>
                      </div>
                    </li> */}
                    <li>
                      <ul className="client-reviews-inner-list">
                        <li>
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </li>
                        <li>
                          {" "}
                          <span className="person-name">Health Upay</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="our-client-reviews-card">
                  <div className="our-client-reviews-content">
                    <p className="desc">
                      I have had the pleasure of working with Team Ebslon for
                      over two years, and during this time, they have
                      consistently exceeded my expectations in providing
                      outstanding CRM and ERP solutions. Their team, led by Mr.
                      Batra and Mr. Aman, has been exceptional in every aspect
                      of their work.
                    </p>
                  </div>
                  <ul className="our-client-reviews-list">
                    {/* <li>
                      <div className="client-details">
                        <img src={clientreviewsimg1} alt='' className='img-fluid'>
                      </div>
                    </li> */}
                    <li>
                      <ul className="client-reviews-inner-list">
                        <li>
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </li>
                        <li>
                          {" "}
                          <span className="person-name">
                            Blue Horse Picture
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-client-reviews-card">
                  <div className="our-client-reviews-content">
                    <p className="desc">
                      I am extremely impressed with the website development
                      services provided by this company. As an interior
                      designer, I needed a website that showcased my work and
                      services to potential clients. The team was able to
                      deliver a stunning website in no time, completing the
                      project before the deadline.
                    </p>
                  </div>
                  <ul className="our-client-reviews-list">
                    {/* <li>
                      <div className="client-details">
                        <img src={clientreviewsimg1} alt='' className='img-fluid'>
                      </div>
                    </li> */}
                    <li>
                      <ul className="client-reviews-inner-list">
                        <li>
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </li>
                        <li>
                          {" "}
                          <span className="person-name">
                            Mobious Design Lab
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-client-reviews-card">
                  <div className="our-client-reviews-content">
                    <p className="desc">
                      I have had the privilege of working with this company for
                      over three years, and in that time, they have consistently
                      demonstrated their expertise, professionalism, and
                      commitment to customer satisfaction. From the initial
                      development stages to ongoing maintenance and support,
                      this company.
                    </p>
                  </div>
                  <ul className="our-client-reviews-list">
                    {/* <li>
                      <div className="client-details">
                        <img src={clientreviewsimg1} alt='' className='img-fluid'>
                      </div>
                    </li> */}
                    <li>
                      <ul className="client-reviews-inner-list">
                        <li>
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </li>
                        <li>
                          {" "}
                          <span className="person-name">Main Value</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-client-reviews-card">
                  <div className="our-client-reviews-content">
                    <p className="desc">
                      I am thrilled to share my outstanding experience
                      collaborating with Ebslon Infotech on our website
                      development project. Their team demonstrated an impeccable
                      combination of technical expertise, creativity, and
                      professionalism, resulting in a user-friendly.
                    </p>
                  </div>
                  <ul className="our-client-reviews-list">
                    {/* <li>
                      <div className="client-details">
                        <img src={clientreviewsimg1} alt='' className='img-fluid'>
                      </div>
                    </li> */}
                    <li>
                      <ul className="client-reviews-inner-list">
                        <li>
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </li>
                        <li>
                          {" "}
                          <span className="person-name">Sundays Forever</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </SwiperSlide>
            </Swiper>
          </Row>
        </Container>
      </section>

      {/* faq-sec */}

      <section className="faq-sec1 fluid-padding py-40">
        <Container fluid>
          <Row>
            <Col xl={10} className="mx-auto">
              <div className="faq-sec-main-head1">
                <h2
                  className="main-heading"
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  Frequently Asked <span>Questions</span>{" "}
                </h2>
              </div>
            </Col>
          </Row>

          <Row className="pt-3 faq-accordion">
            <Col xl={8} className="m-auto">
              <Accordion>
                <Accordion.Item
                  eventKey="0"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <Accordion.Header>
                    What makes your company the best choice for software
                    development?
                    <FontAwesomeIcon className="plus-icon" icon={faPlus} />
                    <FontAwesomeIcon className="minus-icon" icon={faMinus} />
                  </Accordion.Header>
                  <Accordion.Body>
                    Being recognized as one of the best software development
                    companies, we provide top-notch custom software development
                    services that effectively cater to the unique requirements
                    of businesses across various sectors.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="1"
                  data-aos="fade-up"
                  data-aos-duration="2005"
                >
                  <Accordion.Header>
                    I run a healthcare company and need software for better
                    patient management. Can you assist?
                    <FontAwesomeIcon className="plus-icon" icon={faPlus} />
                    <FontAwesomeIcon className="minus-icon" icon={faMinus} />
                  </Accordion.Header>
                  <Accordion.Body>
                    Absolutely, as a reputed healthcare software development
                    company, we can certainly help you with a comprehensive,
                    secure, and user-friendly software system tailored to your
                    needs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="2"
                  data-aos="fade-up"
                  data-aos-duration="2010"
                >
                  <Accordion.Header>
                    We have a multi-level marketing (MLM) model. Do you have the
                    expertise to build software suitable for our business?
                    <FontAwesomeIcon className="plus-icon" icon={faPlus} />
                    <FontAwesomeIcon className="minus-icon" icon={faMinus} />
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, indeed! Being an experienced MLM software development
                    company, we've crafted customized, robust, and easy-to-use
                    software for numerous MLM businesses, enhancing their
                    operations and customer management.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="3"
                  data-aos="fade-up"
                  data-aos-duration="2015"
                >
                  <Accordion.Header>
                    How can you assist in enterprise mobility solutions?
                    <FontAwesomeIcon className="plus-icon" icon={faPlus} />
                    <FontAwesomeIcon className="minus-icon" icon={faMinus} />
                  </Accordion.Header>
                  <Accordion.Body>
                    As an enterprise app development company, we specialize in
                    enterprise mobile app development, delivering secure and
                    efficient applications that boost productivity and enhance
                    user experience across your enterprise.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="4"
                  data-aos="fade-up"
                  data-aos-duration="2020"
                >
                  <Accordion.Header>
                    Can you help us create a robust ERP system?
                    <FontAwesomeIcon className="plus-icon" icon={faPlus} />
                    <FontAwesomeIcon className="minus-icon" icon={faMinus} />
                  </Accordion.Header>
                  <Accordion.Body>
                    Definitely! We are an ERP software development company
                    providing comprehensive ERP development services. We can
                    tailor an ERP system that streamlines your business
                    processes and improves decision-making capabilities.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="eigth-banner1 py-40">
        <div className="container">
          <div className="main_content">
            <div className="row j-c-sb">
              <div className="col-xl-5 col-md-6 col-sm-12 col-12 ">
                <div className="Q-form1">
                  <div className="left">
                    <h5>Get In Touch</h5>
                    <form className="form row">
                      <div className=" col-xl-6 col-12 col-md-12">
                        <input
                          value={firstName2}
                          onChange={(e) => setFirstName2(e.target.value)}
                          type="text"
                          placeholder=" Name"
                          className="form-control"
                        />
                      </div>

                      <div className=" col-xl-6 col-12 col-md-12">
                        <input
                          value={email2}
                          onChange={(e) => setEmail2(e.target.value)}
                          type="text"
                          placeholder=" Email"
                          className="form-control"
                        />
                      </div>
                      <div className=" col-xl-6 col-12 col-md-12">
                        <input
                          value={phone2}
                          onChange={(e) => setPhone2(e.target.value)}
                          type="number"
                          placeholder="Phone Number"
                          className="form-control"
                        />
                      </div>
                      <div className=" col-xl-6 col-12 col-md-12">
                        <input
                          value={companyName2}
                          onChange={(e) => setCompanyName2(e.target.value)}
                          type="text"
                          placeholder="Company Name"
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-12 footer-form">
                        <Form.Select
                          value={solution2}
                          onChange={(e) => setSolution2(e.target.value)}
                        >
                          <option>Select Solution</option>
                          <option>CRM</option>
                          <option> ERP</option>
                          <option>Edtech</option>
                          <option> Ecommerce</option>
                          <option>Hrms</option>
                          <option>Healthcare</option>
                          <option>POS</option>
                          <option>Travel Portal</option>
                          <option value="others">Others</option>
                        </Form.Select>
                      </div>

                      <div className=" col-xl-12 col-12">
                        <textarea
                          value={message2}
                          onChange={(e) => setMessage2(e.target.value)}
                          rows={4}
                          placeholder="How can we help you?"
                          className="form-control"
                        ></textarea>
                      </div>
                      <div className=" col-xl-12 col-12">
                        <div
                          onClick={() => handleSubmit2()}
                          className="enquiry-now-buttn"
                        >
                          Submit
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-5 col-md-5">
                <div className="other-services1">
                  <span>Explore Solutions</span>
                  <h4 className="main-heading">Other Services</h4>

                  <ul className="main-other-services-list">
                    <ul className="other-services-list">
                      <li>
                        <img src={footeruxui} alt="" width={64} height={64} />
                      </li>

                      <li>
                        <h6> UX/UI Design</h6>

                        <p className="desc">
                          Crafting intuitive, visually engaging user interfaces
                          and experiences.
                        </p>
                      </li>
                    </ul>
                    <ul className="other-services-list">
                      <li>
                        <img src={footerweb} alt="" width={64} height={64} />
                      </li>

                      <li>
                        <h6> Web Development</h6>

                        <p className="desc">
                          Building robust, scalable, and interactive websites
                          for businesses.
                        </p>
                      </li>
                    </ul>
                    <ul className="other-services-list">
                      <li>
                        <img src={footerapp} alt="" width={64} height={64} />
                      </li>

                      <li>
                        <h6> App Development</h6>

                        <p className="desc">
                          Creating user-centric, high-performance mobile
                          applications.
                        </p>
                      </li>
                    </ul>
                    <ul className="other-services-list">
                      <li>
                        <img
                          src={footerdigital}
                          alt=""
                          width={64}
                          height={64}
                        />
                      </li>

                      <li>
                        <h6> Digital Marketing</h6>

                        <p className="desc">
                          Boosting online visibility with strategic digital
                          marketing initiatives.
                        </p>
                      </li>
                    </ul>
                    <ul className="other-services-list">
                      <li>
                        <img
                          src={footerchatbot}
                          alt=""
                          width={64}
                          height={64}
                        />
                      </li>

                      <li>
                        <h6> Chatbot</h6>

                        <p className="desc">
                          Boosting user engagement with intelligent chatbot
                          solutions.
                        </p>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* copyright-area */}
      <div className="copyright-area">
        <Col xl={12} className="mx-auto">
          <div className="copyright-text">
            Copyright © 2023, All Right Reserved, Designed & Developed by{" "}
            <a href="https://ebslon.com/" target="_blank">
              Ebslon Infotech
            </a>
          </div>
        </Col>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      {/* whatsapp-buttn */}
                        
      <div
        className={`whatsapp-buttn-box ${
          showWhatsappOnLeft && "whatsapp-buttn-box-left"
        }`}
      >
        <Link
          to="https://api.whatsapp.com/send?phone=919999634388"
          target="_blank"
        >
          ssss
          {" "}
          <img
            src={whatsappicon}
            alt="whatsappicn"
            width={60}
            height={60}
          ></img>
        </Link>
      </div>

      <Modal
        className="modalpop borchure"
        centered
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="closebtnme"></Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6  d-none d-lg-block d-sm-block d-md-block col-12 p-0 ">
              <img
                src={brochure}
                alt=""
                className="img-fluid borchureimgheader"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12  brochurepadding">
              <div className="text-center">
                <h3 className="modal-content-heading"> Download Brochure </h3>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <input
                      onChange={(e) => setName3(e.target.value)}
                      value={name3}
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <input
                      onChange={(e) => setEmail3(e.target.value)}
                      value={email3}
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Email Id"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <input
                      onChange={(e) => setPhone3(e.target.value)}
                      value={phone3}
                      type="tel"
                      className="form-control"
                      placeholder="Enter Your Number"
                    />
                  </div>
                </div>

                <div className="col-lg-12 mt-5">
                  <button
                    onClick={() => HandleSubmitBrochureEnquiry()}
                    className="buttn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EbslonLandingPageUpdate;
