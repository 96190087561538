import React from 'react'
import styles from './styles.module.scss'

export default function UiUxServices() {

  return (
    <>
    <section className={styles.uiux_services}>
<div className="container">
    <div className="row">
        <div className={styles.heading_box}>
            <h2 className={styles.main_heading}>
          <span> Ebslon Infotech </span>   UI/UX DESIGN SERVICES
            </h2>
        </div>
    </div>
    <div className={`row ${styles.pt_30}`}>
        <div className="col-xl-4">
            <div className={styles.card}>
<h5>UI/UX DESIGN</h5>
<ul className={styles.list}>
    <li>
    Examine users' needs, industry standards, and competitors' solutions  </li>

 <li>Elaborate well-defined user personas and user journey maps  </li>

 <li>Perform usability testing for enhanced user flows and conversion optimization  </li>

 <li>Develop a visual identity that aligns with your brand  </li>

 <li>Deliver appealing interfaces using UI design best practices

    </li>
</ul>
            </div>
        </div>
        <div className="col-xl-4">
            <div className={styles.card}>
<h5>PRODUCT PROTOTYPING</h5>
<ul className={styles.list}>
    <li>
    Validate the feasibility of the concept with the product's information architecture
  </li>

 <li>
Prioritize key product features with a feature map
  </li>

 <li>
Ensure proper access control, security, and a personalized UX with a user role matrix

 </li>

 <li>Visualize the layout, structure, and basic functionality of the user interface with wireframes  </li>


</ul>
            </div>
        </div>
        <div className="col-xl-4">
            <div className={styles.card}>
<h5>MULTIPLATFORM DESIGN

</h5>
<ul className={styles.list}>
    <li>
    Obtain a consistent UX across web, mobile, wearables, and IoT devices
  </li>

 <li>
Elaborate user roles with role-specific functions that are properly distributed across platforms  </li>

</ul>
            </div>
        </div>
        <div className="col-xl-4">
            <div className={styles.card}>
<h5>DESIGN SYSTEM IMPLEMENTATION

</h5>
<ul className={styles.list}>
    <li>
    Create stunning visuals and deliver a scalable and well-documented design system to keep the product’s look and feel consistent

  </li>

 <li>Speed up implementation of new features with a modular design approach  </li>


</ul>
            </div>
        </div>
        <div className="col-xl-4">
            <div className={styles.card}>
<h5>WEB/MOBILE DESIGN





</h5>
<ul className={styles.list}>
    <li>
    Ensure UX consistency across devices and platforms  </li>

 <li>Adopt a product mindset and comply with industry-specific design patterns  </li>

 <li>Meet WCAG accessibility guidelines to ensure wide user accessibility </li>


</ul>
            </div>
        </div>
        <div className="col-xl-4">
            <div className={styles.card}>
<h5>AUDIT AND REDESIGN





</h5>
<ul className={styles.list}>
    <li>
    Conduct thorough analysis, user research, and usability and accessibility evaluations </li>

 <li>Create user personas and user journeys  </li>

 <li>Obtain a report with suggested improvements  </li>


</ul>
            </div>
        </div>
    </div>
</div>
    </section>
    
    </>
  )
}
