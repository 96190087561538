import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

function UiUxBanner() {
  return (
    <>
      <section className={styles.digital_banner}>
        <Container>
          <Row>
            <Col xl={4} className={styles.content}>
              <h1 className={styles.main_heading}>UI/UX DESIGN SERVICES</h1>
              <p className={styles.desc}>
                At its core, our UI UX design services are based on
                human-centric design principles. We take a holistic approach to
                designing your product by diving deep into how your users will
                interact with every aspect of your product.
              </p>

              <Link to="/contact-us" className={styles.transform_buttn}>
                Get in Touch
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default UiUxBanner;
