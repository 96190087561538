import axios from "axios";
// import { axiosApiInstance } from "./axios.service";
import { apiUrl } from "./url.service";

const serverUrl = apiUrl + "/landingPage";

export const addLandingPage = (obj) => {
    return axios.post(`${serverUrl}/`, obj);
};
export const getAllLandingPages = (paginationObj = { pageIndex: 0, pageSize: 10 }, obj = {}) => {
    let query = new URLSearchParams({ ...paginationObj, ...obj });
    return axios.get(`${serverUrl}/?${query.toString()}`)
};

export const BrochureDownloadApi = (formData) => {
    return axios.post(`${apiUrl}/enquiry/brochure-download-enquiry`, formData);
}
