import React from "react";
import bannerplywood from "../../assets/images/topbanner/bannerimgplywod.webp";
import certifi11 from "../../assets/images/topbanner/certificate1.webp";
import certificate2 from "../../assets/images/topbanner/certificate2.webp";
import certificate3 from "../../assets/images/topbanner/certificate3.webp";
import certificate4 from "../../assets/images/topbanner/certificate4.webp";

function Homebannersectoin() {
  return (
    <section className="homebannersectoin">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-7 col-sm-7 col-md-7 col-12 d-flex align-items-center">
            <div className="bannerheading">
              <h1>Empowering Businesses with Cutting-Edge</h1>
              <h2>Web and App Development Strategies</h2>
              <p>
                We Assist Individuals And Businesses In Creating Web And Mobile
                Applications Swiftly, Cost-Effectively, And With Greater
                Efficiency By Linking Startups With Pre- Screened Development
                Teams, Specifically Curated For Their Projects.
              </p>
              <h6>Our Certificate</h6>
              <ul className="certificate_img_list">
                <li>
                  <img src={certifi11} alt="" />{" "}
                </li>
                <li>
                  <img src={certificate2} alt="" />{" "}
                </li>
                <li>
                  <img src={certificate3} alt="" />{" "}
                </li>
                <li>
                  <img src={certificate4} alt="" />{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 col-sm-5 col-md-5 col-12">
            <div className="home_banner_img">
              <img
                src={bannerplywood}
                alt="bannerplywood"
                fetchpriority="high"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Homebannersectoin;
