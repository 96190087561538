import React from "react";
import { useContext } from "react";
import { Pagecontext } from "../../App";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function CurrentLocation() {
  const [pageUrl, setPageUrl] = useContext(Pagecontext);
  let location = useLocation();

  useEffect(() => {
    setPageUrl(location?.pathname);
  }, [location]);

  return <></>;
}
