import React, { useState } from 'react'
import styles from './styles.module.scss'

import reacticon from '../../../../assets/images/ARandVR/tools/react.webp'
import Vuforia from '../../../../assets/images/ARandVR/tools/vuforia.webp'
import Unity from '../../../../assets/images/ARandVR/tools/unity.webp'
import Unreal from '../../../../assets/images/ARandVR/tools/unreal-engine.webp'
import Blender from '../../../../assets/images/ARandVR/tools/Blender_logo_no_text-1920x1570.webp'
import MAX from '../../../../assets/images/ARandVR/tools/Autodesk-3ds-Max-icon.webp'

export default function ARTools() {

const [industriesarr,SetIndustriesArr] = useState([
    {
        image:reacticon,
        heading:"React",
    },
    {
        image:Vuforia,
        heading:"Vuforia",
    },
    {
        image:Unity,
        heading:"Unity",
    },
    {
        image:Unreal,
        heading:"Unreal Engine",
    },
    {
        image:Blender,
        heading:"Blender",
    },
    {
        image:MAX,
        heading:"3DS Max",
    },
    

])



  return (
    <>
    <section className={styles.tools_sec}>
<div className="container">
<div className="row">
        <h2 className={styles.main_heading}> 
TOOLS & PLATFORMS USED IN VR DEVELOPMENT
</h2>
        <p className={styles.desc}>Augmented Reality and Virtual Reality App Development Tools To Create Different Solutions</p>
    </div>

    <div className={`row ${styles.pt_50}`}>
       {
        industriesarr && industriesarr?.map((el,index)=>(
            <div className="col-xl-2 col-lg-2 col-md-4" key={index}>
            <div className={styles.card}>
<div className={styles.image}>
<img src={el.image} alt=''/>
</div>
<h5>{el.heading}</h5>
            </div>
        </div>
         ))
       } 
    </div>

</div>
    </section>
    
    </>
  )
}
