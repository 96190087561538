import React from "react";
import Callicon from "../../assets/images/callimg.webp";
import { useEffect } from "react";
import { useState } from "react";
function CallButtn() {
  const [showWhatsappOnLeft, setShowWhatsappOnLeft] = useState(false);

  useEffect(() => {
    if (navigator) {
      console.log(navigator.userAgent, "useragent");
      if (
        navigator.userAgent.toLowerCase().includes("iphone") ||
        navigator.userAgent.toLowerCase().includes("android")
      ) {
        setShowWhatsappOnLeft(false);
      } else {
        setShowWhatsappOnLeft(true);
      }
    }
  }, []);
  return (
    <>
      <div className="call_buttn_box">
        <a href="tel:9999634388" target="_blank">
          <img src={Callicon} alt="whatsappicn" width={50} height={50}></img>
        </a>
      </div>
    </>
  );
}

export default CallButtn;
