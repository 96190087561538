
import React from 'react'
import ARBanner from './ARandVR/Components/ARBanner/ARBanner'
import WeOffer from './ARandVR/Components/WeOffer/WeOffer'
import ARApproach from './ARandVR/Components/ARApproach/ARApproach'
import WhyChoose from './ARandVR/Components/WhyChoose/WhyChoose'
import Industries from './ARandVR/Components/Industries/Industries'
import MLContact from './ARandVR/Components/MLContact/MLContact'
import ARTools from './ARandVR/Components/ARTools/ARTools'
import { Helmet } from "react-helmet";



export default function ARandVR() {
  return (
    <>

      <Helmet>
        <link rel="canonical" href="https://ebslon.com/ar-vr-app-development"/>
          <title>
            AR/VR Development Services in India | AR VR Development Service | Ebslon Infotech {" "}
          </title>
          <meta
            name="description"
            content="Get the top AR/VR solutions and AR/VR Development Services in India. Develop immersive games, realistic simulations, and other experiences with our expertise with 16+yrs experience. Contact us today -  9999634388."
          />
          <meta name="keywords" content="AR/VR Development Services in India, AR VR Development Service, AR VR Development India, AR/VR Development solutions,  AR/VR Development Delhi NCR " />
          <meta
            property="og:title"
            content="AR/VR Development Services in India | AR VR Development Service | Ebslon Infotech"
          />
          <meta
            property="og:descroption"
            content="Get the top AR/VR solutions and AR/VR Development Services in India. Develop immersive games, realistic simulations, and other experiences with our expertise with 16+yrs experience. Contact us today -  9999634388. "
          />
          <meta property="og:type" content=" AR/VR Development Services" />
          <meta property="og:url" content="https://ebslon.com/ar-vr-app-development"
          />
          <meta
            property="og:image"
            content="https://ebslon.com/logo1.png"
          />
      </Helmet>
      <ARBanner />
      <WeOffer />
      <ARApproach />
      <WhyChoose />
      <Industries />
      <MLContact />
      <ARTools />
    </>
  )
}
