import React from 'react'
import styles from './styles.module.scss'

import weoffer1 from '../../../../assets/images/ARandVR/weoffer/1.webp'
import weoffer2 from '../../../../assets/images/ARandVR/weoffer/2.webp'


export default function WeOffer() {
  return (
    <>
    <section className={styles.we_offer}>
        <div className="container">
            <div className={`row ${styles.py_20}`}>
                <div className="col-xl-4">
                    <div className={styles.heading}>
<h2>WE OFFER</h2>
                    </div>
                </div>
                <div className="col-xl-7">
                    <div className={styles.content}>
                        <p>We offer innovative solutions to streamline processes, increase productivity, and deliver unparalleled user experiences that drive results. Our AR/VR solutions can help you achieve just that, providing an unprecedented level of engagement and interactivity that will leave a lasting impression on your audience.</p>
                    </div>
                </div>
            </div>
            <div className={`row ${styles.pt_40}`}>
                <div className="col-xl-5 col-lg-6 col-md-6">
                    <div className={styles.offer_card}>
<div className={styles.image}>
<img src={weoffer1} alt='' />
</div>
<div className={styles.content}>
<h4>AUGMENTED REALITY</h4>
<p>Unlock the potential of augmented reality with our development service. Our expert team creates immersive and interactive AR experiences that captivate and engage your audience.</p>
</div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-6">
                    <div className={styles.offer_card}>
<div className={styles.image}>
<img src={weoffer2} alt='' />
</div>
<div className={styles.content}>
<h4>VIRTUAL REALITY</h4>
<p>Transform your brand with our cutting-edge virtual reality development service. Our expert team creates engaging and immersive VR experiences that captivate your audience.</p>
</div>
                    </div>
                </div>
               
            </div>
        </div>
    </section>
    
    </>
  )
}
