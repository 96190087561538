import React from 'react'
import styles from './styles.module.scss'

import topnotch from '../../../../assets/images/ARandVR/whychoose/top-notch-vr-development-icon.webp'
import flexible from '../../../../assets/images/ARandVR/whychoose/cooperation-flexibility-icon.webp'
import projects from '../../../../assets/images/ARandVR/whychoose/dozens-of-projects-icon.webp'
import optimization from '../../../../assets/images/ARandVR/whychoose/advanced-optimization-icon.webp'

export default function WhyChoose() {
  return (
    <>
<section className={styles.why_choose}>
<div className="container">
    <div className="row">
        <h2 className={styles.main_heading}>Why Choose Us for AR and VR App Development</h2>
    </div>
    <div className={`row ${styles.pt_40}`}>
        <div className="col-xl-6 col-lg-6">
            <div className={styles.why_card}>
<div className="row">
    <div className="col-xl-2 col-lg-2 col-md-2 col-3">
        <div className={styles.icon}>
<img src={topnotch} alt='' />
        </div>
    </div>
    <div className="col-xl-10 col-lg-10 col-md-10 col-9">
        <div className={styles.content}>
<h5>Top-notch VR development</h5>
<p>Our multidimensional specialists know how to develop any virtual reality solution and incorporate it into your project, making it resonate with the overall business strategy.</p>
        </div>
    </div>
</div>
            </div>
        </div>
        <div className="col-xl-6 col-lg-6">
            <div className={styles.why_card}>
<div className="row">
    <div className="col-xl-2 col-lg-2 col-md-2 col-3">
        <div className={styles.icon}>
<img src={flexible} alt='' />
        </div>
    </div>
    <div className="col-xl-10 col-lg-10 col-md-10 col-9">
        <div className={styles.content}>
<h5>Cooperation flexibility</h5>
<p>Our multidimensional specialists know how to develop any virtual reality solution and incorporate it into your project, making it resonate with the overall business strategy.</p>
        </div>
    </div>
</div>
            </div>
        </div>
        <div className="col-xl-6 col-lg-6">
            <div className={styles.why_card}>
<div className="row">
    <div className="col-xl-2 col-lg-2 col-md-2 col-3">
        <div className={styles.icon}>
<img src={projects} alt='' />
        </div>
    </div>
    <div className="col-xl-10 col-lg-10 col-md-10 col-9">
        <div className={styles.content}>
<h5>Dozens of projects behind</h5>
<p>
Program-Ace has delivered a high number of commercially successful VR projects that continue to pay dividends for many business owners worldwide.</p>
        </div>
    </div>
</div>
            </div>
        </div>
        <div className="col-xl-6 col-lg-6">
            <div className={styles.why_card}>
<div className="row">
    <div className="col-xl-2 col-lg-2 col-md-2 col-3">
        <div className={styles.icon}>
<img src={optimization} alt='' />
        </div>
    </div>
    <div className="col-xl-10 col-lg-10 col-md-10 col-9">
        <div className={styles.content}>
<h5>Advanced optimization</h5>
<p>Virtual reality applications heavily depend on a client’s hardware, so our developers adhere to the most high-end optimization practices to make your VR app efficient.</p>
        </div>
    </div>
</div>
            </div>
        </div>
      
    </div>
</div>
</section>

    </>
  )
}
