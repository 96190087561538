import React, { useState } from "react";
import toast from "react-hot-toast";
import { addLandingPage } from "../../services/landingPage.service";


export default function BlogsNeedAConsultation() {

  const [name, SetName] = useState();

  const [email, SetEmail] = useState();
  const [phone, SetPhone] = useState();
  const [companyName, SetCompanyName] = useState();
  const [message, SetMessage] = useState();

  const [services, SetServices] = useState([

  ]);


  const [servicesArr, SetServicesArr] = useState([
    {
      value: "Software App Development",

    },
    {
      value: "Web Designing",

    },
    {
      value: "Web Development",

    },
    {
      value: "Digital Marketing",

    },
  ])



  const handleServices = (e, value) => {
    const checked = e.target.checked;
    if (checked) {
      SetServices([...services, { value }]);
    } else {
      SetServices(services.filter((service) => service.value !== value));
    }
    console.log(services, "sersjhfs")
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    let fullname = name.split(" ");
    console.log("fullname",fullname)
    
    const FirstName = fullname[0] || ""
    const LastName = fullname.length >1 ? fullname[1] : ""
    console.log("fnmae",FirstName)
    console.log("lnmae",LastName)
    try {
      if (name == "") {
        toast.error("Name is required");
        return;
      }
     


      if (email == "") {
        toast.error("Email is required");
        return;
      }
      if (phone == "") {
        toast.error("Phone is required");
        return;
      } else {
        let obj = {
          firstName:FirstName,
          lastName:LastName,
          email,
          phone,
          companyName,
          message,
          services,
          // servicePage,
        };

        console.log("objdgdge",obj)
        let { data: res } = await addLandingPage(obj);
        if (res) {
          console.log(res, "dataatataat")
          toast.success("Form submitted successfully");
          SetName("")
          SetEmail("")
          SetPhone("")
          SetCompanyName("")
          SetMessage("")
          SetServices([])
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
    // e.preventDefault();
    // const token = captchaRef.current.getvalue();
    // captchaRef.current.reset();
  };



  return (
    <section className="consultation" >
      <div className="container-fluid">
        <div className="row gx-0 ">
          <div className="col-xl-8 mx-auto">
            <div className="left">
              <div className="title-section mb-3">
                <h3 className="heading">
                  Let's Have A  <span className="yellow">Discussion ?</span>
                </h3>
                <p>
                  Drop us a line! We are here to answer your questions 24/7.
                </p>

                <div className="checkbox_area">
                  <h5 className="mb-3">Which Services do You Want?</h5>

                  <ul className="df_jcsb">
                    {
                      servicesArr?.map((el, index) => (
                        <li key={index}>
                          <input type="checkbox" className="accentColor" value={el.value} onChange={(e) => handleServices(e, el.value, index)} /> <span>{el.value} </span>
                        </li>
                      ))
                    }


                  </ul>
                </div>
              </div>
              <form className="form row">
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      SetName(e.target.value);
                    }}
                    placeholder="Full Name"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => {
                      SetCompanyName(e.target.value);
                    }}
                    placeholder="Company"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      SetEmail(e.target.value);
                    }}
                    placeholder="Work Email"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <input
                    type="number"
                    maxLength="10"
                    value={phone}
                    onChange={(e) => {
                      SetPhone(e.target.value);
                    }}
                    placeholder="Work Phone"
                    className="form-control"
                  />
                </div>
                <div className="col-12">
                  <textarea
                    value={message}
                    onChange={(e) => {
                      SetMessage(e.target.value);
                    }}
                    placeholder="How can we help you?"
                    rows="3"
                    className="form-control"
                  ></textarea>
                </div>

                {/* google recaptcha */}
                {/* <div className="recaptcha mb-3">
                  <ReCAPTCHA
                    sitekey="6LfmwBopAAAAACmf8EFybW6H2oYvHwAtbS0Wtf3I"
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={onChange}
                  />
                </div> */}

                <div className="col-12">
                  <button
                    type="submit"
                    className="btn text-white yellow-bg btn-lg fw-bold px-4"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
