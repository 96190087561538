
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

function ARBanner() {
  return (
   <>
   

       <section
        className={styles.manufacturing_banner}>
        <Container>
          <Row>
            <Col xl={4} className={styles.content}>
              <h1 className={styles.main_heading}>
                
             AR/VR Development Services

              </h1>
              <p className={styles.desc}> Take your business to new heights with our immersive AR/VR development service. Our experienced team creates engaging solutions to optimize processes, boost productivity, and revolutionize product/service delivery across industries.

</p>

              <Link to="/contact-us" className={styles.transform_buttn}>
                Let's Discuss Your Project
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
   
   </>
  )
}

export default ARBanner