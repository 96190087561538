import React from "react";
// import { Link } from "react-router-dom";
// import { images } from "./Utility/Images";
// import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { postServiceContApi } from "../services/front.service";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlinePhone } from "react-icons/hi";
import { useState } from "react";
import { toast } from "react-hot-toast";
 import { useNavigate } from "react-router-dom";
import { addLandingPage } from "../services/landingPage.service";

// import { useSearchParams } from "react-router-dom";
// import { Helmet } from "react-helmet";

function Formfooter(props) {

  const navigate = useNavigate()

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [company, SetCompany] = useState("");
  const [message, SetMessage] = useState("");

 

  var path = window.location.pathname;
  var parts = path.split("/");
  var data = parts[1];
  console.log(data, "data data");

const [servicePage, SetServicePage] = useState(data);

  const handleSubmit = async (e) => {
e.preventDefault();

let fullname = name.split(" ");
console.log("fullname",fullname)

const FirstName = fullname[0] || ""
const LastName = fullname.length >1 ? fullname[1] : ""
console.log("fnmae",FirstName)
console.log("lnmae",LastName)

    try {

     
      if (name == "" || name == undefined) {
        toast.error("Name is required");
        return;
      }

     
      if (email == "" || email == undefined) {
        toast.error("Email is required");
        return;
      }
      if (phone == "" || phone == undefined) {
        toast.error("Phone is required");
        return;
      } else {
        let obj = {
          name,
          firstName:FirstName,
          lastName:LastName,
          email,
          phone,
          company,
          message,
          servicePage,
        };
        let { data: res } = await addLandingPage(obj);
        if (res.message) {
          toast.success("Form submitted successfully");
         
          // window.location.href = "/ThankYouPage";
          navigate("/ThankYouPage");
          // return
            
          // console.log(res);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  
  return (
    <>
 
      <div className="eigth-banner ptb-40">
        <div className="container">
          <div className="main_content pt-5 pb-5">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 Query_text">
                <h4>
                  Got questions or need help? We're just a click or call away.
                </h4>
                <p className="mt-3 mb-4">
                  We're here to help and answer any question you might have.
                  Whether you need more information on our services, have
                  feedback, or just want to say hello, we'd love to hear from
                  you. Reach out to us and we'll respond as soon as we can.
                </p>

                <div className="contact_onfo">
                  <div className="row">
                    <div className="col-lg-3 border-right">
                      <div className="icon svgiocn ">
                        <HiOutlineMail className="ms-auto" />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="info_date ps-2">
                        <h6>MAIL TO OUR COMPANY</h6>
                        <a href="mailto:info@ebslon.com">info@ebslon.com</a>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-3 border-right">
                      <div className="icon svgiocn ">
                        <HiOutlinePhone className="ms-auto" />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="info_date ps-2">
                        <h6>OUR NUMBER</h6>
                        <a href="tel:9999634388">+91-9999634388</a>
                      </div>
                    </div>
                  </div>
                </div>
              

                <ul>
                  <h5 className="mb-3">What you will get:</h5>
                  <li>On-call inquiry assistance</li>
                  <li>Project consulting by experts</li>
                  <li>Quick project estimation</li>
                </ul>
              </div>

              <div className="col-lg-1 p-0 d-none d-lg-block">
                <hr />
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12 col-12 Q-form pt-5">
                <div className="left">
                  <form className="form row">
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        placeholder="Full Name"
                        value={name}
                        onChange={(e) => {
                          SetName(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        placeholder="Company"
                        value={company}
                        onChange={(e) => {
                          SetCompany(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        placeholder="Work Email"
                        value={email}
                        onChange={(e) => {
                          SetEmail(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        placeholder="Work Phone"
                        maxLength="10"
                        value={phone}
                        onChange={(e) => {
                          SetPhone(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        placeholder="How can we help you?"
                        rows="3"
                        value={message}
                        onChange={(e) => {
                          SetMessage(e.target.value);
                        }}
                        className="form-control"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button
                      type="submit"
                        className="btn text-white yellow-bg btn-lg fw-bold px-4 mt-3"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Formfooter;
