import React from 'react'
import styles from './styles.module.scss'
import techstack from '../../../../assets/images/ui-ux/techimg/techstack.webp'

export default function UiUxTech() {
  return (
   <>
   <section className={styles.uiux_tech}>
<div className="container">
    <div className="row">
        <h2 className={styles.main_heading}>
        Our Technology Stack
        </h2>
    </div>
    <div className={`row ${styles.pt_30}`}>
      <div className="col-xl-12">
      <div className={styles.image}>
            <img src={techstack} alt="" />
        </div>
      </div>
    </div>
</div>
   </section>
   
   </>
  )
}
