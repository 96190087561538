import React from "react";
import { images } from "./Utility/Images";
import AOS from "aos";
const Ourclients = () => {
  AOS.init();
  return (
    <div>
       <div className="clicent bg-clicent banner ptb-80 " data-aos="fade-up">
        <div className="container-fluid">
          <div className="title-section mb-5 text-center">
            <h1 className="heading">
              A few of <span className="yellow"> our clients </span>
            </h1>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="our-clients">
                <div className="d-flex">
                  <div className="marquee-items left">
                    <div className="items_logo">
                      <img src={images.clicentlogo1} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo2} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo3} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo4} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo5} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo6} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo57} alt="" />
                    </div>
                  </div>
                  <div className="marquee-items left">
                    <div className="items_logo">
                      <img src={images.clicentlogo58} alt="" />
                    </div>

                    <div className="items_logo">
                      <img src={images.clicentlogo9} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo10} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo11} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo12} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo13} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo14} alt="" />
                    </div>
                  </div>
                </div>
                <div className="boottom_marquee">
                  <div className="d-flex">
                    <div className="marquee-items right">
                      <div className="items_logo">
                        <img src={images.clicentlogo15} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo16} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo17} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo18} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo19} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo20} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo21} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo59} alt="" />
                      </div>
                    </div>
                    <div className="marquee-items right">
                      <div className="items_logo">
                        <img src={images.clicentlogo22} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo23} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo49} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo50} alt="" />
                      </div>

                      <div className="items_logo">
                        <img src={images.clicentlogo60} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo61} alt="" />
                      </div>
                      <div className="items_logo">
                        <img src={images.clicentlogo51} alt="" />
                      </div>

                      <div className="items_logo">
                        <img src={images.clicentlogo46} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="marquee-items left">
                    <div className="items_logo">
                      <img src={images.clicentlogo47} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo48} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo32} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo33} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo34} alt="" />
                    </div>
                    {/* <div className="items_logo">
                      <img src={images.clicentlogo35} alt="" />
                    </div> */}
                    <div className="items_logo">
                      <img src={images.clicentlogo36} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo37} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo38} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo52} alt="" />
                    </div>

                    <div className="items_logo">
                      <img src={images.clicentlogo23} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo54} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo62} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo63} alt="" />
                    </div>

                    <div className="items_logo">
                      <img src={images.clicentlogo27} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo29} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo30} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo31} alt="" />
                    </div>
                    {/* <div className="items_logo">
                      <img src={images.clicentlogo31} alt="" />
                    </div> */}
                    <div className="items_logo">
                      <img src={images.clicentlogo32} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo33} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo34} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo35} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo36} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo37} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo38} alt="" />
                    </div>
                    {/* <div className="items_logo">
                      <img src={images.clicentlogo34} alt="" />
                    </div> */}
                    {/* <div className="items_logo">
                      <img src={images.clicentlogo35} alt="" />
                    </div> */}
                    <div className="items_logo">
                      <img src={images.clicentlogo36} alt="" />
                    </div>
                  </div>
                  <div className="marquee-items left">
                    <div className="items_logo">
                      <img src={images.clicentlogo37} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo38} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo65} alt="" />
                    </div>

                    <div className="items_logo">
                      <img src={images.clicentlogo64} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo65} alt="" />
                    </div>
                    {/*    <div className="items_logo">
                      <img src={images.clicentlogo25} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo25} alt="" />
                    </div> */}

                    <div className="items_logo">
                      <img src={images.clicentlogo27} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo29} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo63} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo31} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo64} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo32} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo33} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo34} alt="" />
                    </div>
                    {/* <div className="items_logo">
                      <img src={images.clicentlogo35} alt="" />
                    </div> */}
                    <div className="items_logo">
                      <img src={images.clicentlogo36} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo37} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo38} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo39} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo40} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo41} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo42} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo43} alt="" />
                    </div>
                    <div className="items_logo">
                      <img src={images.clicentlogo44} alt="" />
                    </div>
                    <div className="items_logo">
                      a
                      <img src={images.clicentlogo45} alt="" />
                    </div>
                    <div className="items_logo">
                      a
                      <img src={images.clicentlogo49} alt="" />
                    </div>
                    <div className="items_logo">
                      a
                      <img src={images.clicentlogo50} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ourclients
