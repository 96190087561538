import React from 'react'
import styles from './styles.module.scss'
import uiuxcase from '../../../../assets/images/ui-ux/casestudy/uiuxcase.webp'

export default function UiUxCaseStudy() {
  return (
    <>
    <section className={styles.uiux_case}>
        <div className="container">
<div className={`row ${styles.j_c_sb}`}>
    <div className="col-xl-5">
        <div className={styles.image}>
<img src={uiuxcase} alt="" />
        </div>
    </div>
    <div className="col-xl-6">
        <div className={styles.content}>
<span>Case Study</span>
<h4>Building a Responsive UX To Facilitate Real-Time Updates & Customer Service</h4>
<p>Our client, Empower Data, is a Saas platform that provides AI-based analytics sales opportunities for industrial OEMs. The client's web application consolidated the data from multiple sources and showed real-time updates in the dashboard.</p>
<p>The client decided to build a mobile application to provide timely updates to its contractors. Since they had no prior experience building native applications, they were looking to outsource the application's frontend develpoment.</p>
<p>
As their product development partner, we built a highly intuitive app suiting the needs of the end-user. Take a look at how our well-planned and coordinated development helped our client satisfy their customers.
</p>
        </div>
    </div>
</div>
        </div>
    </section>
    
    
    </>
  )
}
