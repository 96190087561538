import React from 'react'
import styles from './styles.module.scss'

export default function UiUxBenefits() {
  return (
    <>
    <section className={styles.uiux_benefits}>
<div className="container">
<div className="row">
        <div className={styles.heading_box}>
            <h2 className={styles.main_heading}>
            BENEFITS OF  <span> Ebslon Infotech UI/UX SERVICES </span>   

            </h2>
        </div>
    </div>
    <div className={`row ${styles.pt_30}`}>
        <div className="col-xl-6">
            <div className={styles.card}>
<div className="row">
    <div className="col-2">
        <div className={styles.number}>
            01
        </div>
    </div>
    <div className="col-10">
        <div className={styles.content}>
            <h5>
            EFFICIENT STRATEGY FOR DESIGN

</h5>
            <p className={styles.desc}>
            Leverage our distinctive systematic strategy to craft solutions aligned with user requirements and organizational objectives. This strategy entails thorough exploration of the domain, analysis of competitors, and understanding user preferences to ascertain essential features. We then proceed with brainstorming sessions, prototyping, and rigorous testing. This iterative method ensures refinement, as we continuously iterate through the process. Our design strategy seamlessly integrates Design Thinking (DT) and User-Centered Design (UCD) principles, adaptable to project nuances with flexibility to incorporate novel elements as needed.

            </p>
        </div>
    </div>
</div>
            </div>
        </div>
        <div className="col-xl-6">
            <div className={styles.card}>
<div className="row">
    <div className="col-2">
        <div className={styles.number}>
            02
        </div>
    </div>
    <div className="col-10">
        <div className={styles.content}>
            <h5>
            RIGID ADHERENCE TO DESIGN THINKING (DT)

</h5>
            <p className={styles.desc}>
      
       
Committing to design thinking ensures understanding user needs, questioning assumptions, reframing challenges, and innovating solutions for testing. This approach yields a competitive product, unique differentiation, and access to new markets. Our systematic strategy includes domain exploration, competitor analysis, and user preference understanding. We follow with brainstorming, prototyping, and rigorous testing, iterating for refinement. Seamlessly integrating DT and UCD principles, our approach adapts to project nuances, accommodating novel elements.


            </p>
        </div>
    </div>
</div>
            </div>
        </div>
        <div className="col-xl-6">
            <div className={styles.card}>
<div className="row">
    <div className="col-2">
        <div className={styles.number}>
            03
        </div>
    </div>
    <div className="col-10">
        <div className={styles.content}>
            <h5>
            USER-CENTERED DESIGN (UCD)
</h5>
            <p className={styles.desc}>
           

            At Ebslon Infotech, we prioritize user needs through our User-Centered Design (UCD) approach. By empathizing with users, we uncover insights to inform every stage of development. Our process involves thorough research, ideation, prototyping, and testing to ensure solutions resonate with users. Iterative refinement guarantees optimal usability, enhancing user satisfaction and product success. Seamlessly integrating UCD principles into our design strategy, we deliver solutions tailored to meet both user expectations and business objectives.


            </p>
        </div>
    </div>
</div>
            </div>
        </div>
        <div className="col-xl-6">
            <div className={styles.card}>
<div className="row">
    <div className="col-2">
        <div className={styles.number}>
            04
        </div>
    </div>
    <div className="col-10">
        <div className={styles.content}>
            <h5>
            DESIGN EXCELLENCE
</h5>
            <p className={styles.desc}>
            

            Ebslon Infotech excels in design, employing a meticulous approach to craft innovative solutions. Our process begins with comprehensive research to understand market dynamics and user preferences. Through iterative ideation, prototyping, and testing, we ensure every aspect of our designs meets the highest standards. By seamlessly integrating cutting-edge technologies and creative expertise, we deliver products that not only meet user needs but also set new benchmarks for excellence in design.


            </p>
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
</div>
    </section>
    
    
    
    </>
  )
}
