import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'


export default function MLContact() {
  return (
    <>
    <section className={styles.contact_banner}>
        <div className="container">
            <div className={`row ${styles.contact_wrapper}`}>
               <div className="col-xl-8 col-lg-8 col-12">
                <div className={styles.content}>
                    <h5>Want to give your app idea the wings of reality?</h5>
                    <h3>Partner with us for end-to-end mobile app development services.</h3>
                </div>
               </div>
               <div className="col-xl-3 col-lg-3 col-12">
                <div className={styles.buttn_holder}>
                    <Link to="/contact-us" className={styles.opp_buttn}>Get a Free Quote</Link>
                </div>
               </div>
            </div>
        </div>
    </section>
    
    </>
  )
}
