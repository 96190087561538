import React, { useState } from 'react'
import styles from './styles.module.scss'

import medical from '../../../../assets/images/ARandVR/industries/medical.webp'
import education from '../../../../assets/images/ARandVR/industries/education.webp'
import safety from '../../../../assets/images/ARandVR/industries/workplace.webp'
import oilandgas from '../../../../assets/images/ARandVR/industries/oilandgas.webp'
import automotive from '../../../../assets/images/ARandVR/industries/automotive.webp'
import minning from '../../../../assets/images/ARandVR/industries/mining.webp'
import defence from '../../../../assets/images/ARandVR/industries/defence.webp'
import aviation from '../../../../assets/images/ARandVR/industries/aviation.webp'

export default function Industries() {

const [industriesarr,SetIndustriesArr] = useState([
    {
        image:medical,
        heading:"Medical",
    },
    {
        image:education,
        heading:"Education",
    },
    {
        image:safety,
        heading:"Safety",
    },
    {
        image:oilandgas,
        heading:"Oil & Gas",
    },
    {
        image:automotive,
        heading:"Automotive",
    },
    {
        image:defence,
        heading:"Defence",
    },
    {
        image:minning,
        heading:"Mining",
    },
    {
        image:aviation,
        heading:"Aviation",
    },

])



  return (
    <>
    <section className={styles.industries_sec}>
<div className="container">
<div className="row">
        <h2 className={styles.main_heading}> AR / VR APP DEVELOPMENT FOR INDUSTRIES</h2>
        <p className={styles.desc}>Augmented Reality and Virtual Reality Company Solutions for different industries</p>
    </div>

    <div className={`row ${styles.pt_50}`}>
       {
        industriesarr && industriesarr?.map((el,index)=>(
            <div className="col-xl-3 col-lg-3 col-md-6" key={index}>
            <div className={styles.card}>
<div className={styles.image}>
<img src={el.image} alt='' />
</div>
<h5>{el.heading}</h5>
            </div>
        </div>
        ))
       }
    </div>

</div>
    </section>
    
    </>
  )
}
