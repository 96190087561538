import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { images } from "./Utility/Images";
import brandregister from "../assets/images/MarketplaceManagement/services/brandregister.webp";
import productresearch from "../assets/images/MarketplaceManagement/services/productresearch.webp";
import enhance from "../assets/images/MarketplaceManagement/services/enhance.webp";
import Advertising from "../assets/images/MarketplaceManagement/services/advertisement.webp";
import account from "../assets/images/MarketplaceManagement/services/account.webp";
import consult from "../assets/images/MarketplaceManagement/services/consultation.webp";
// import Marketplacebanner from "../assets/images/MarketplaceManagement/marketplacebanner.webp";

function MarketplaceManagement() {
  return (
    <>
      {/* real-estate-banner-sec */}

      <section
        className="marketplace-banner-sec"
        // style={{
        //   width: "100%",
        //   minHeight: "550px",
        //   backgroundImage: `url(${Marketplacebanner})`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        <Container>
          <Row>
            <div className="marketplace-banner-content ">
              <h1 className="main-heading">Online Marketplace Management</h1>
              <p className="sub-heading">
                We are a leading eCommerce app development company in India. We
                can build custom eCommerce applications that match with your
                business on Android and iOS platforms & help clients in the
                entire eCommerce app development cycle.
              </p>

              <Link to="/contact-us" className="transform-button">
                Get a Free Demo
              </Link>
            </div>
          </Row>
        </Container>
      </section>

      {/* marketplace services */}

      <section className="marektplace_services_sec py-40">
        <Container>
          <Row className="pb-4">
            <Col xl={12} className="erp-development-services-head mx-auto">
              <h2 className="main-heading-type">
                {" "}
                Ebslon Infotech <span>Online MarketPlace Management</span>{" "}
                Services{" "}
              </h2>
            </Col>
          </Row>

          <Row className="py-3">
            <div className="erp-developed-modules-list-box">
              <Row className="align-items-center">
                <Col xl={6} md={6}>
                  <div className="erp-developed-modules-card">
                    <Row className="align-items-center">
                      <Col xl={2} xs={4} className="erp-developed-modules-icon">
                        <img src={brandregister} className="img-fluid" alt="" />
                      </Col>
                      <Col
                        xl={10}
                        xs={8}
                        className="erp-developed-modules-content"
                      >
                        <h4>Brand Register</h4>
                        <p className="desc">
                          Brand registration is a crucial step for protecting
                          your intellectual property. It grants legal rights to
                          your brand name, logo, or slogan, preventing others
                          from using or copying them without permission.
                          Registering your brand with relevant authorities
                          ensures exclusivity and safeguards your brand's
                          identity.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xl={6} md={6}>
                  <div className="erp-developed-modules-card">
                    <Row className="align-items-center">
                      <Col xl={2} xs={4} className="erp-developed-modules-icon">
                        <img
                          src={productresearch}
                          className="img-fluid"
                          alt=""
                        />
                      </Col>
                      <Col
                        xl={10}
                        xs={8}
                        className="erp-developed-modules-content"
                      >
                        <h4>Product Research & Catalogue creation </h4>
                        <p className="desc">
                          Product Research & Catalogue Creation: We meticulously
                          analyze market trends, competitor offerings, and
                          consumer preferences to curate a comprehensive and
                          appealing product catalogue. Our goal is to help you
                          showcase your products effectively, driving sales and
                          customer engagement.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col xl={6} md={6}>
                  <div className="erp-developed-modules-card">
                    <Row className="align-items-center">
                      <Col xl={2} xs={4} className="erp-developed-modules-icon">
                        <img
                          src={enhance}
                          className="img-fluid"
                          alt=""
                        />
                      </Col>
                      <Col
                        xl={10}
                        xs={8}
                        className="erp-developed-modules-content"
                      >
                        <h4>Enhanced Brand Content </h4>
                        <p className="desc">
                        Elevate your brand's story with captivating visuals, compelling narratives, and interactive features. Our EBC service maximizes your Amazon product listings' appeal, converting browsers into loyal customers. Stand out and thrive.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xl={6} md={6}>
                  <div className="erp-developed-modules-card">
                    <Row className="align-items-center">
                      <Col xl={2} xs={4} className="erp-developed-modules-icon">
                        <img
                          src={account}
                          className="img-fluid"
                          alt=""
                        />
                      </Col>
                      <Col
                        xl={10}
                        xs={8}
                        className="erp-developed-modules-content"
                      >
                        <h4>Account Monitoring </h4>
                        <p className="desc">
                        Our vigilant oversight ensures your accounts remain secure and optimized. We proactively track activity, detect anomalies, and swiftly respond to maintain peak performance. Peace of mind, safeguarded success.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col xl={6} md={6}>
                  <div className="erp-developed-modules-card">
                    <Row className="align-items-center">
                      <Col xl={2} xs={4} className="erp-developed-modules-icon">
                        <img
                          src={Advertising}
                          className="img-fluid"
                          alt=""
                        />
                      </Col>
                      <Col
                        xl={10}
                        xs={8}
                        className="erp-developed-modules-content"
                      >
                        <h4>Advertising Campaign </h4>
                        <p className="desc">
                        Our expert team crafts data-driven, persuasive ad campaigns tailored to your goals. We optimize targeting, creative elements, and budget allocation to maximize ROI. Whether it's on social media, search engines, or other platforms, we drive results, enhancing your brand's visibility and profitability. Success starts here.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xl={6} md={6}>
                  <div className="erp-developed-modules-card">
                    <Row className="align-items-center">
                      <Col xl={2} xs={4} className="erp-developed-modules-icon">
                        <img
                          src={consult}
                          className="img-fluid"
                          alt=""
                        />
                      </Col>
                      <Col
                        xl={10}
                        xs={8}
                        className="erp-developed-modules-content"
                      >
                        <h4>Business Intelligence & Consultation</h4>
                        <p className="desc">
                        We offer data-driven insights and strategic guidance to optimize your business. Our experts analyze market trends, competitors, and internal data to provide actionable recommendations. Harness the power of information to make informed decisions, drive growth, and stay ahead in a dynamic business landscape.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MarketplaceManagement;
