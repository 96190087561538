import React from 'react'
import styles from './styles.module.scss'

export default function UiUxSolutions() {
  return (
    <>
    <section className={styles.roadblock_sec}>

    </section>
    <section className={styles.card_sec}>
        <div className="container">
        <div className={`row ${styles.j_c_c}`}>

<div className="col-xl-4">
    <div className={styles.card}>
<h5>Your Roadblock</h5>
<ul className={styles.list}>
    <li>
    Do you need help with creating cohesive designs across different platforms? </li>

<li>Are your users seeking personalized experience across digital touchpoints? </li>

<li>Is the inability to create a seamless user flow and navigation killing your product?
    </li>
</ul>
    </div>
</div>
<div className="col-xl-4">
    <div className={styles.card}>
<h5>Our Solution</h5>
<ul className={styles.list}>
    <li>
    We craft a uniform brand identity for you using engaging designs and delightful UI/UX services.
 </li>

<li>
Our UI UX design services translate complex ideas into smooth experiences.

 </li>

<li>We prioritize customer experience using intuitive navigation, resulting in increased customer loyalty and retention rates.
    </li>
</ul>
    </div>
</div>
            </div>
 
        </div>
    </section>
    
    
    </>
  )
}
