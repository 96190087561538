"use client"
import React from 'react'
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.module.scss'

function UiUxFAQ() {
  return (
   <>
   
  <section className={styles.faq_sec}>
        <div className='container'>
          <div className="row">
            <div  className={`${styles.heading_box} col-xl-8 mx-auto`}>
              <h2>
                Frequently Asked <span>Questions</span>
              </h2>
            </div>
          </div>

          <div className={`faq-accordion ${styles.py_40}`}>
          <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                What is the difference between UI and UX?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                UI (User Interface) refers to the visual elements and layout of a software application, while UX (User Experience) encompasses the overall experience and usability of the product, including factors like user flow, interaction design, and user satisfaction.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                Why is it important to distinguish between UI and UX?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Distinguishing between UI and UX helps in understanding their respective roles in creating a successful product. While UI focuses on the aesthetics and presentation, UX emphasizes the overall user journey and satisfaction, ensuring a seamless and delightful experience.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                How does UI design contribute to the overall user experience?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                UI design contributes to the overall user experience by creating visually appealing interfaces that are intuitive to navigate, consistent in design language, and effectively communicate information to the user.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                What aspects of UX design are not directly related to UI?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Aspects of UX design not directly related to UI include user research, user personas, user journey mapping, usability testing, and overall product strategy. These elements focus on understanding user needs and optimizing the product experience beyond just its visual appearance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                Can a product have good UX but poor UI, or vice versa?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Yes, a product can have good UX but poor UI, meaning it provides a great user experience despite lacking visually appealing design elements. Conversely, a product can have good UI but poor UX if it looks attractive but fails to meet user needs or is difficult to use.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                How does Ebslon Infotech approach UI/UX design for its products?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                At Ebslon Infotech, we adopt a user-centered design approach, starting with comprehensive user research to understand user needs and behaviors. Our UI/UX designers collaborate closely to create interfaces that are not only visually appealing but also intuitive and user-friendly.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                What tools and methodologies does Ebslon Infotech use for UI/UX design?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Ebslon Infotech utilizes a range of tools and methodologies for UI/UX design, including Adobe XD, Sketch, Figma, user personas, wireframing, prototyping, usability testing, and iterative design processes.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                How does Ebslon Infotech ensure accessibility in its UI/UX designs?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Ebslon Infotech prioritizes accessibility in UI/UX designs by adhering to accessibility standards such as WCAG (Web Content Accessibility Guidelines), conducting accessibility audits, and incorporating features like alternative text for images, keyboard navigation support, and color contrast considerations.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          
        </div>
      </section>
   
   </>
  )
}

export default UiUxFAQ