import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import Logoimgblack from "../../assets/images/EbslonLandingPage/img/main-logo/black.webp";
import { Link } from "react-router-dom";
import whatsappicon from "../../assets/images/EbslonLandingPage/images/ecommerce/whatsapp.webp";
import { addLandingPage } from "../../services/landingPage.service";
import { toastError, toastSuccess } from "../../utils/toast";
function ContactForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showWhatsappOnLeft, setShowWhatsappOnLeft] = useState(false);

  useEffect(() => {
    if (navigator) {
      console.log(navigator.userAgent, "useragent");
      if (
        navigator.userAgent.toLowerCase().includes("iphone") ||
        navigator.userAgent.toLowerCase().includes("android")
      ) {
        setShowWhatsappOnLeft(false);
      } else {
        setShowWhatsappOnLeft(true);
      }
    }
  }, []);

  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [value, setValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [solution, setSolution] = useState("");

  const handleSubmit = async () => {
    try {
      if (firstName == "") {
        toastError("Name is mandatory !!!");
        return;
      }
      // if (lastName == "") {
      //   toastError("Last Name is mandatory !!!");
      //   return;
      // }
      if (email == "") {
        toastError("Email is mandatory !!!");
        return;
      }
      if (email && !(email.includes("@") && email.includes("."))) {
        toastError("Invalid Email provided !!!");
        return;
      }
      if (phone == "") {
        toastError("Phone Number is mandatory !!!");
        return;
      }
      if (phone && phone.length !== 10) {
        toastError(
          "Invalid Phone Number provided , phone number must be 10 digits !!!"
        );
        return;
      }

      let obj = {
        firstName,
        lastName,
        email,
        phone,
        message,
        companyName,
        solution,
        page: window.location.pathname,
      };

      let { data: res } = await addLandingPage(obj);

      if (res.message) {
        toastSuccess(res.message);
      }
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className="contact-form-bg-white main-banner-sec1 fluid-padding ">
      {/* main-banner-sec */}

      <header className="main-header1 fluid-padding">
        <Container fluid>
          <Row className="a-i-c">
            <Col xl={3} lg={3} md={3} xs={6}>
              <div className="main-logo1">
                <a href="https://ebslon.com/" target="_blank">
                  <img src={Logoimgblack} alt="" className="img-fluid" />
                </a>
              </div>
            </Col>
            <Col xl={9} lg={9} md={9} xs={6}>
              <div className="header-contact-area">
                <Link
                  to="/ContactForm"
                  className="req-quote-buttn1"
                  target="_blank"
                >
                  Get Quote
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="contact-form-sec fluid-padding">
        <Container fluid>
          <Row>
            <Col xl={5} lg={6} md={8} className="mx-auto margin-top-5">
              <div className="contact-form-box1">
                <h4 className="form-head">Request a callback</h4>
                <p className="desc">
                  Request a callback for a quick discussion on your software
                  development needs.
                </p>
                <div className="contact-form-body mt-3">
                  <div className="row">
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Name"
                          aria-label="Username"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Phone Number"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Email"
                          aria-label="Username"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="col-xl-12">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Company Name"
                          aria-label="Username"
                          // onChange={(e) => setPhone(e.target.value)}
                          // value={phone}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12 footer-form mb-3">
                      <Form.Select>
                        <option>Select Solution</option>
                        <option>CRM</option>
                        <option> ERP</option>
                        <option>Edtech</option>
                        <option> Ecommerce</option>
                        <option>Hrms</option>
                        <option>Healthcare</option>
                        <option>POS</option>
                        <option>Travel Portal</option>
                        <option value="others">Others</option>
                      </Form.Select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        style={{ minHeight: "100px" }}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        className="mb-3 message-textarea"
                      />
                    </div>
                  </div>

                  <p className="t-c">
                    <div
                      onClick={() => handleSubmit()}
                      className="enquiry-now-buttn"
                    >
                      Submit
                    </div>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* copyright-area */}
      <div className="copyright-area">
        <Col xl={12} className="mx-auto">
          <div className="copyright-text">
            Copyright © 2023, All Right Reserved, Designed & Developed by{" "}
            <a href="https://ebslon.com/" target="_blank">
              Ebslon Infotech
            </a>
          </div>
        </Col>
      </div>

      {/* whatsapp-buttn */}

      <div
        className={`whatsapp-buttn-box ${
          showWhatsappOnLeft && "whatsapp-buttn-box-left"
        }`}
      >
        <a
          href="https://api.whatsapp.com/send?phone=9999634388"
          target="_blank"
        >
          {" "}
          <img
            src={whatsappicon}
            alt="whatsappicn"
            width={60}
            height={60}
          ></img>
        </a>
      </div>
    </div>
  );
}

export default ContactForm;
