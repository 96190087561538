import React from 'react'
import UiUxBannerr from './UiandUx/Components/UiUxBanner/UiUxBanner'
import UiUxAboutus from './UiandUx/Components/UiUxAboutus/UiUxAboutus'
import UiUxServices from './UiandUx/Components/UiUxServices/UiUxServices'
import UiUxBenefits from './UiandUx/Components/UiUxBenefits/UiUxBenefits'
import UiUxSolutions from './UiandUx/Components/UiUxSolutions/UiUxSolutions'
import UiUxTech from './UiandUx/Components/UiUxTech/UiUxTech'
import UiUxCaseStudy from './UiandUx/Components/UiUxCaseStudy/UiUxCaseStudy'
import RetailFAQ from './UiUxFAQ/UiUxFAQ'
import { Helmet } from 'react-helmet'


export default function UiandUx() {
  return (
   <>
   <Helmet>
        <link rel="canonical" href="https://ebslon.com/ui-and-ux-design"/>
          <title>
     UI/UX Design Services | Call today at 9999634388 | Ebslon Infotech Pvt Ltd {" "}
          </title>
          <meta
            name="description"
            content=" Ebslon Infotech is one of the best UI/UX Design Services provider company in India. As the most trusted company, we have been providing UI/UX Design since 2016. Contact us today at 9999634388."
          />
          <meta name="keywords" content="   UI/UX Design Services,  UI/UX Design Services in India, UI/UX Design Services in Delhi NCR" />
          <meta
            property="og:title"
            content="  UI/UX Design Services | Call today at 9999634388 | Ebslon Infotech Pvt Ltd  "
          />
          <meta
            property="og:descroption"
            content=" Ebslon Infotech is one of the best UI/UX Design Services provider company in India. As the most trusted company, we have been providing UI/UX Design since 2016. Contact us today at 9999634388."
          />
          <meta property="og:type" content=" UI/UX Design Services " />
          <meta property="og:url" content="https://ebslon.com/ui-and-ux-design"
          />
          <meta
            property="og:image"
            content="https://ebslon.com/static/media/logo.914bd4f2fac6515b6802.png"
          />
      </Helmet>
   <UiUxBannerr/>
   <UiUxAboutus/>
   <UiUxSolutions/>
   <UiUxServices/>
   <UiUxBenefits/>
   <UiUxTech/>
  
   <UiUxCaseStudy/>
   <RetailFAQ/>

   </>
  )
}
