import React from 'react'
import styles from './styles.module.scss'
import { InputGroup, Form } from "react-bootstrap";
import { ImArrowUpRight2 } from 'react-icons/im';
import { Link } from 'react-router-dom';

export default function MainBanner() {
  return (
   <>
    <section className={styles.main_banner_sec}>
       <div className="container_fluid">
<div className={`row  ${styles.pt_40}`}>
    <div className="col-xxl-6 col-xl-7 col-lg-7 col-md-6">
        <div className={styles.content}>
<h1>
Find the <span> Best App Development</span>  Company
</h1>
<p className={styles.desc}>
Delivering excellence as a top-tier software development company, Ebson Infotech specializes in tailored custom software development services, propelling businesses to new heights.
</p>
<ul className={styles.list}>
  <li>
    <h5>
    500 +
    </h5>
    <p>
    Project Completed
    </p>
  </li>
  <li>
    <h5>
    200 +

    </h5>
    <p>
    Happy Client’s
    </p>
  </li>
  <li>
    <h5>
    99 %
    </h5>
    <p>


Delivery Rate
    </p>
  </li>







</ul>

<p className={styles.buttn_holder}>
                  <Link
                    to="/contact-us"
                    className={styles.enquiry_buttn}
                    target="_blank"
                  >
                    Get Free Consultation{" "}
                    <span>
                      <ImArrowUpRight2 />
                    </span>
                  </Link>
                </p>
        </div>

    </div>

    <div className='col-xxl-4 col-xl-4 col-lg-5 col-md-6'>
              <div className={styles.top_contact_form}>
                <h4 className={styles.form_head}>Request a callback</h4>
              
                <div className={styles.form_body}>
                  <div className="row">
                    <div className="col-xl-12">
                      <InputGroup className={styles.ip_box}>
                        <Form.Control
                          placeholder="Name"
                          aria-label="Username"
                       
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="col-xl-12">
                    <InputGroup className={styles.ip_box}>
                        <Form.Control
                          placeholder="Phone Number"
                          aria-label="Username"
                        
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                    <InputGroup className={styles.ip_box}>
                        <Form.Control
                          placeholder="Email"
                          aria-label="Username"
                        
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="col-xl-12">
                    <InputGroup className={styles.ip_box}>
                        <Form.Control
                          placeholder="Company Name"
                          
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                        <div className={styles.select_box}>
                        <Form.Select
                       
                      >
                        <option>Select Solution</option>
                        <option>CRM</option>
                        <option> ERP</option>
                        <option>Edtech</option>
                        <option> Ecommerce</option>
                        <option>Hrms</option>
                        <option>Healthcare</option>
                        <option>POS</option>
                        <option>Travel Portal</option>
                        <option value="others">Others</option>
                      </Form.Select>
                        </div>
                     
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                        <div className={styles.ip_box}>
                        <Form.Control
                        as="textarea"
                        placeholder="Message"
                        style={{ minHeight: "100px" }}
                       
                        
                      />
                        </div>
                   
                    </div>
                  </div>

                  <div className="t-c">
                    <div
                    
                      className="enquiry-now-buttn"
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </div>

             
            </div>
</div>
</div>
       </section>
   
   
   </>
  )
}
