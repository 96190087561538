
import React, { useState } from 'react'
import styles from './styles.module.scss';
import ahilya from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/Aahilya.webp";
import artisaga from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/artisaga1.webp";
import toneitup from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/toneitup.webp";
import fasto from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/fasto.webp";
import meraki from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/meraki.webp";
import nikkypore from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/nikkypore1.webp";
import rocare from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/rocare_india1.webp";
import scentoria from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/scentoria.webp";
import ceressy from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/ceressy1.webp";
import chiratae from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/chiratae.webp";
import creek from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/creek.webp";
import favcy from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/favcy1.webp";
import huddlehearts from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/huddle-hearts.webp";
import marelli from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/marelli.webp";
// import mobius from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/mobius.webp";
import momporium from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/momporium.webp";
import paramount from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/paramount.webp";
// import pratham from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/pratham.webp";
// import stanum from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/stanum.webp";
// import woo from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/woo.webp";

// import shringaaar from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/shringaaar.webp";
import sundaysforever from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/sundaysforever1.webp";
import voiceoc from "../../../../assets/images/EbslonLandingPage/img/clicents-logo/voiceoc.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";





export default function HomeTechnologies() {

  const [clientArr,SetClientArr] = useState([
    {
      image:ahilya,
    },
    {
      image:artisaga,
    },
    {
      image:fasto,
    },
    {
      image:toneitup,
    },
    {
      image:meraki,
    },
    {
      image:nikkypore,
    },
    {
      image:rocare,
    },
    {
      image:scentoria,
    },
    {
      image:ceressy,
    },
    {
      image:chiratae,
    },
    {
      image:paramount,
    },
    {
      image:creek,
    },
    {
      image:favcy,
    },
    {
      image:sundaysforever,
    },
    {
      image:voiceoc,
    },
    {
      image:huddlehearts,
    },
    {
      image:marelli,
    },
    {
      image:huddlehearts,
    },
    {
      image:momporium,
    },
  ])
  return (
    <>
    <section className={styles.web_tech}>
        <div className="container">
          <div className="row">
            <div className={styles.heading_box}>
              <h2 className={styles.main_heading}>
           A Few of <span> Our Clients</span>
              </h2>
            </div>
          </div>
          <ul className={`row ${styles.pt_30}`}>

          <Swiper
              slidesPerView={6}
              spaceBetween={20}
              loop={true}
              speed={700}
              autoplay={true}
              modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 2,

                  spaceBetween: 20,
                },

                640: {
                  slidesPerView: 4,

                  spaceBetween: 20,
                },

                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },

                1024: {
                  slidesPerView: 6,

                  spaceBetween: 20,
                },
              }}
              className={`mySwiper ${styles.py_20}`}
            >
              {
                clientArr.map((el,index)=>(
                  <SwiperSlide key={index}>
            
                <div className={styles.image}>
               <img src={el.image}  alt=""  />
              
               </div>
              
        

                  </SwiperSlide>
                ))
              }
           

              </Swiper>



          
           
           
          </ul>
        </div>
      </section>
    
    </>
  )
}
