import React from "react";
import { Link } from "react-router-dom";
import { images } from "../Utility/Images";
import { GrMail } from "react-icons/gr";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { BiChevronRight } from "react-icons/bi";
import CallButtn from "../HomePageComponents/CallButtn";
import WhatsappButtn from "../HomePageComponents/WhatsappButtn";

function Footer() {
  return (
    <>
     

      <footer className="footor-outer">
        <div className="footer">
          <div className="container-fluid">
            <div className="row justify-content-center">
            <div className="col-12 col-lg-2">
                <div className="footer-links">
                  <Link to="/" className="navbar-brand">
                    <img src={images.logo_2} className="main-logo" alt="" />
                  </Link>
                  <p className="mt-3">
                    Ebslon Infotech, established in 2016, is a visionary company
                    dedicated to providing cutting-edge enterprise solutions
                    such as ERP, CRM, and factory automation software to drive
                    business growth and enhance operational efficiency. Our
                    comprehensive suite of IT offerings includes specialized
                    applications that address vital business needs for any industry.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-2 col-md-3">
                <div className="footer-links">
                  <h5 className="title">Company</h5>
                  <ul className="footer-links-list">
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/"> Home</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/about"> ABOUT US</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/portfolio"> Portfolio</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/technologies"> TECHNOLOGIES</Link>
                    </li>
                   
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/case-studies"> CASE STUDIES</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/blog"> BLOG</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/PrivacyPolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/TermCondition">Terms & Conditions</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/ReturnRefund">Return And Refund </Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/TestimonialPartner">
                        Testimonial & Clients
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-2 col-md-3">
                <div className="footer-links">
                  <h5 className="title">Industries</h5>
                  <ul className="footer-links-list">
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/educational-app-development">ED Tech</Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/custom-crm-development-services"> CRM</Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/ecommerce-app-development"> Ecommerce</Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/travel-app-development"> Travel</Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/manufacturing-software-development">
                        {" "}
                        Manufacturing
                      </Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/fintech-app-development-company">
                        {" "}
                        FinTech
                      </Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/healthcare-software-development">
                        {" "}
                        Healthcare
                      </Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/real-estate-app-development">
                        {" "}
                        Real Estate
                      </Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/society-management-app-development">
                        {" "}
                        Society Management
                      </Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/retail-POS-software">
                        {" "}
                        Retail POS Software
                      </Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/grocery-app-development"> Grocery</Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/job-portal-app-development"> Job Portal</Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/custom-erp-software-development-company">
                        {" "}
                        ERP Systems
                      </Link>
                    </li>

                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/school-management-software-development-company">
                        {" "}
                        School App Management
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
             
              <div className="col-12 col-lg-2 col-md-3">
                <div className="footer-links">
                  <h5 className="title">Digital Marketing</h5>
                  <ul className="footer-links-list">
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/digital-marketing-agency">SEO Service</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/digital-marketing-agency">SMM Marketing</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/digital-marketing-agency">SMO Service</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/digital-marketing-agency">PPC Marketing</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/digital-marketing-agency">WhatsApp Marketing</Link>
                    </li>
                    <li>
                      <div className="icon">
                        <BiChevronRight />
                      </div>
                      <Link to="/digital-marketing-agency">Content Marketing</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-3">
                <div className="footer-links">
                  <h5 className="title">Contact Us</h5>
                  <ul className="contact-links">
                    <li>
                      <div className="icon yellow">
                        <GrMail />
                      </div>
                      <div>
                        <h6> Email Id</h6>
                        <div className="inner">
                          <a href="mailto:info@ebslon.com">info@ebslon.com</a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="icon yellow">
                        <FaPhoneAlt />
                      </div>
                      <div>
                        <h6> Phone Number</h6>
                        <div className="inner">
                          <a href="tel:9999634388">
                            {" "}
                            <span>India : </span> +91-9999634388
                          </a>
                        </div>
                        <div className="inner">
                     
                          <a href="tel:02030265160">
                            {" "}
                            <span> U.K. : </span> +44-02030265160
                          </a>
                          {/* <a href="tel:+447828 697713">
                            {" "}
                            <span> U.K. : </span> +44-7828 697713
                          </a> */}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="icon yellow">
                        <ImLocation />
                      </div>
                      <div>
                        <h6> India (HQ)</h6>
                        <div className="inner">
                          <a href="https://goo.gl/maps/f7Q9KLRiiB1aF7Zt6">
                          No. A, DMALL, 1207, 1, Netaji Subhash Place, Pitampura, Delhi, 110034
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="icon yellow">
                        <ImLocation />
                      </div>
                      <div>
                        <h6> UK Office</h6>
                        <div className="inner">
                        <a href="https://maps.app.goo.gl/9FE9q496nGtv4AQ58" rel="noreferrer" target="_blank">
                  167-169 Great Portland street, 5th Floor, London, W1W 5PF
                        </a>
                     
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/ebsloninfotech/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ebsloninfotech/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/ebslon-infotech/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/ebsloninfotech"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/message/5X3JK7P5ARXAD1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaWhatsapp />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright">
            <div className="container">
              <p>
                Copyright &copy; 2023, All Right Reserved, Designed & Developed
                by{" "}
                <a className="" href="https://ebslon.com/">
                  Ebslon Infotech
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      <CallButtn />

<WhatsappButtn />

    </>
  );
}

export default Footer;
