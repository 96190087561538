import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { images } from "./Utility/Images";
import {AiOutlineCheckCircle} from 'react-icons/ai'
import {BsFillArrowRightSquareFill} from 'react-icons/bs'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";

function DeyorCaseStudy() {
  return (
    <>
      {/* breadcrumb-for-caseStudy */}

      <section className="breadcrumb-area">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="breadcrumb-casestudy-box">
                <ul className="breadcrumb-casestudy-list">
                  <li>
                    <Link to="/">Home </Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/case-studies">Case Study</Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/DeyorCaseStudy">Deyor</Link>{" "}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* deyor-main-banner-sec */}

      <section className="deyor-main-banner-sec">
        <Container>
          <Row>
            <Col xl={5} lg={7}>
              <div className="deyor-main-banner-content">
                <div className="deyor-main-banner-logo">
                  <img
                    src={images.Deyorlogo}
                    alt="deyorlogo"
                    className="img-fluid"
                  />
                </div>
                <p className="desc">
                  Deyor is an travelling website for tour package. Users will
                  view the jewelry and buy the items/products. Also, the user
                  will be able to add the items to the My Favorites list.
                </p>

                <ul className="deyor-content-list">
                  <li>
                    <span>Industry</span>
                    <h5>Service</h5>
                  </li>
                  <li>
                    <span>Services</span>
                    <h5>Online Tour and Travel</h5>
                  </li>

                  <li>
                    <span>Duration</span>
                    <h5>6 Month</h5>
                  </li>
                </ul>

                <p className="mt-30">
                  <Link className="visit-site-buttn">Visit Site</Link>
                </p>
              </div>
            </Col>

          </Row>
        </Container>
      </section>

      {/* deyor-about-us */}

      <section className="deyor-objective-sec py-40">
        <Container>
          <Row>
            <Col xl={10} className="mx-auto">
            <div className="deyor-objective-main-content-box ">
                <h2 className="main-heading">
             Our Objectives
                </h2>
                {/* <p className="desc">To provide a travel solution that are :</p> */}
                </div>
            </Col>
        
          </Row>
          <Row className="pt-3">

          <Col xl={6} md={5}>
              <div className="deyor-objective-img">
<img src={images.Deyorobjectiveimg} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col xl={6} md={7}>
         
<div className="deyor-objective-content-box">
  <Row>
    <Col xl={6} md={6}>
    <div className="deyor-objective-card">
<span className="number-area">01.</span>
<p className="desc1">
Offers real-time itinerary customization spanning flights, hotels, and activities.
</p>
      </div>
   
    </Col>
    <Col xl={6} md={6}>
    <div className="deyor-objective-card">
<span className="number-area">02.</span>
<p className="desc1">
Introduces a specialized module for biking enthusiasts with varied package preferences.
</p>
      </div>
    </Col>
  </Row>


    
    
  
</div>

      
            </Col>
          
            </Row>


          {/* <Row>
            <Col xl={12}>
              <div className="deyor-about-us-wrapper">
                <Row className=" j-c-sb">
                  <Col xl={6} lg={6}>
                    <div className="deyor-about-us-image-box">
                      <div className="deyor-about-us-main-img">
                        <img
                          src={images.Deyoraboutusimg}
                          alt=""
                          className="img-fluid"
                        />
                      </div>

                    
                    </div>
                  </Col>
                  <Col xl={5} lg={5}>
                    <div className="deyor-about-us-content">
                      <span className="main-heading">
                        Deyor{" "}
                        <img
                          src={images.Deyoraboutrytarrow}
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                      <p className="desc">To provide a travel solution that</p>

                      <ul className="deyor-about-us-content-list">
                        <li>
                          <span className="list-bar"></span>
                          Offers real-time itinerary customization spanning flights, hotels, and activities.
                        </li>
                        <li>
                          {" "}
                          <span className="list-bar"></span> Introduces a specialized module for biking enthusiasts with varied package preferences.
                        </li>
                      
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>

      {/* methodology-sec */}
      <section className="deyor-methodology-sec py-40">
<Container>
  <Row className="pb-2">
    <Col xl={10} className="mx-auto">
      <div className="deyor-methodology-main-head">
       <h2 className="main-heading">
       Features  </h2> 
      </div>
    </Col>
  </Row>

  <Row className="pt-3">
  <Col xl={4} lg={4}>
      <div className="deyor-methodology-content-box">
     
<h5 className="sub-heading">1. Integration of Flights and  Hotels </h5>
<div className="deyor-features-content-box">
  <ul className="deyor-features-content-list">
<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>Instantaneous Updates</h6>
  <p>By partnering with TBO Company, Deyor ensures users are provided with real-time, accurate data for flights, hotels, and activities.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>Tailored Journey Plans</h6>
  <p>Users are empowered to handpick their choice of hotels, flights, and activities. The portal then dynamically crafts an itinerary based on these preferences.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>One-stop Booking</h6>
  <p>Integrated APIs allow users to make immediate reservations for their chosen flights or accommodations, eliminating the need for multiple platforms.</p>
    </li>
  </ul>


</li>

  </ul>
</div>

<div className="implementation-box">
  <h3>
    Implementation
  </h3>
<ul className="deyor-features-content-list">
<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>Backend Integration</h6>
  <p>TBO Company's API was incorporated into Deyor's backend infrastructure to facilitate real-time data fetch and update mechanisms.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>User Interface Enhancement</h6>
  <p>The platform's frontend was designed to be intuitive, allowing for seamless selection and booking processes.</p>
    </li>
  </ul>


</li>



  </ul>
</div>

<div className="outcomes-box">
  <h3>
    Outcomes
  </h3>
<ul className="deyor-features-content-list">
<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
  <p>Enhanced user satisfaction owing to the seamlessness of the booking experience.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
   
  <p>Increased user retention due to the platform's ability to offer real-time data and customization.</p>
    </li>
  </ul>


</li>



  </ul>
</div>
      </div>
    </Col>

    <Col xl={4} lg={4}>
<div className="deyor-methodology-img">
<img
                    src={images.Deyorfeatureimg3}
                    alt=""
                    className="img-fluid"
                  />
<img
                    src={images.Deyorfeatureimg4}
                    alt=""
                    className="img-fluid mt-10"
                  />
         
</div>
    </Col>

    <Col xl={4} lg={4}>
      <div className="deyor-methodology-content-box">
<h5 className="sub-heading">2. Bike Trips Module</h5>
<div className="deyor-features-content-box">
  <ul className="deyor-features-content-list">
<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>Diverse Package Selection</h6>
  <p>The platform offers varied options such as Own Vehicle, Dual Rider, and Solo Rider, addressing the varied preferences of the biking community.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>Inclusive Packages 
 </h6>
  <p>Comprehensive packages include all essentials, from accommodations to meals, ensuring a smooth travel experience.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>Transparent Trip Details</h6>
  <p>Each package is designed with full disclosure, including trip duration, starting and ending locations, and clear pricing.</p>
    </li>
  </ul>


</li>

  </ul>
  <div className="implementation-box-1">
  <h3>
    Implementation
  </h3>
<ul className="deyor-features-content-list">
<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>Package Structuring</h6>
  <p>Collaboration with biking communities and experts to understand user preferences and design packages accordingly.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
    <h6>UI/UX Design</h6>
  <p>Intuitive interface development to display packages, their inclusions, and other relevant details in a user-friendly manner.</p>
    </li>
  </ul>


</li>



  </ul>
</div>

<div className="outcomes-box-1">
  <h3>
    Outcomes
  </h3>
<ul className="deyor-features-content-list">
<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
  <p>Positioned Deyor as a pioneer in offering specialized biking packages in the online travel industry.</p>
    </li>
  </ul>


</li>

<li className="content-list-li">
  <ul className="deyor-features-inner-list">
    <li>
    <AiOutlineCheckCircle/>
    </li>
    <li>
   
  <p>Attracted a niche biking community to the platform, increasing overall user diversity.</p>
    </li>
  </ul>


</li>



  </ul>
</div>

</div>
      </div>
    </Col>
   

  </Row>
</Container>
      </section>

  
      {/*challenges-sec */}

      <section className="deyor-challenges-sec py-40">
        <Container>
          <Row className="pb-3 d-f-fd-cr-deyor j-c-sa">
            <Col xl={5} lg={5} className="deyor-challenges-wrap-box">
              <div className="deyor-challenges-content-box">
                <span className="small-bar"></span>
                <h2 className="main-heading">Impact</h2>
                <p className="desc">
                 <ul className="challenges-content-list">
                  <li>
                    <ul className="challenges-inner-list">
                      <li>
                        <BsFillArrowRightSquareFill/>
                      </li>
                      <li>
                      <h5>User Growth</h5>
                  <p className="desc">
                  Deyor's unique offerings, coupled with real-time data, attracted a significant influx of new users, leading to a steady growth rate month-over-month.
                  </p>
                      </li>
                    </ul>
                    
                  </li>
                  <li>
                    <ul className="challenges-inner-list">
                      <li>
                        <BsFillArrowRightSquareFill/>
                      </li>
                      <li>
                      <h5> Revenue Increase</h5>
                  <p className="desc">
                  The inclusion of the specialized biking module opened up a new revenue stream, amplifying the platform's profitability.
                  </p>
                      </li>
                    </ul>
                    
                  </li>
                  <li>
                    <ul className="challenges-inner-list">
                      <li>
                        <BsFillArrowRightSquareFill/>
                      </li>
                      <li>
                      <h5>Market Position </h5>
                  <p className="desc">
                  The successful integration and unique offerings solidified Deyor's position as a formidable contender in the digital travel industry.
                  </p>
                      </li>
                    </ul>
                    
                  </li>
                
                 </ul>
                </p>
              </div>
            </Col>

            <Col xl={6} lg={6} className="padding-zero">
              <div className="deyor-challenges-img">
                <img
                  src={images.Deyorchallengesimg}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* solutions-sec */}

      <section className="deyor-solutions-sec py-40">
        <Container>
          <Row className="j-c-sa d-f-fd-cr-deyor">
            <Col xl={6} lg={6}>
              <div className="deyor-solutions-img">
                <img
                  src={images.Deyorsolutionsimg}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col xl={5} lg={5} className="padding-zero">
              <div className="deyor-solutions-content-box">
                <span className="small-bar"></span>
                <h2 className="main-heading">Conclusion</h2>
                <p className="desc">
                Deyor's success can be attributed to its ability to innovate and adapt to the ever-evolving needs of modern travelers. By offering real-time data integrations and unique biking packages, the platform not only addressed gaps in the market but also provided users with unparalleled flexibility and choice. The case of Deyor exemplifies how technological integration and understanding user needs can revolutionize the travel experience in the digital age.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* work-screens */}
      <section className="deyor-work-screens-sec py-40">
        <Container>
          <Row className="pb-3">
            <Col xl={10} className="mx-auto">
              <div className="deyor-work-screens-main-head">
                <h2 className="main-heading">
                  Check Out Our Other Awesome Work
                </h2>
                <p className="desc">
                  Here are some of our outstanding application development works
                  done by us
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              speed={900}
              loop={true}
              autoplay={{
                delay: 1500,
                disableOnInteraction: true,
              }}
              modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },

                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              className="mySwiper py-3"
            >
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen1}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen2}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen3}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen4}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen5}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen6}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen7}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img
                    src={images.Deyorworkscreen8}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </SwiperSlide>


            </Swiper>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default DeyorCaseStudy;
