import React, { useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logoimgblack from "../../assets/images/EbslonLandingPage/img/main-logo/black.webp";
import checkgif from "../../assets/images/EbslonLandingPage/img/Thankyoupage/check-mark.gif";
import whatsappicon from "../../assets/images/EbslonLandingPage/images/ecommerce/whatsapp.webp";
import { Link, useNavigate } from "react-router-dom";
import Aos from "aos";

function ThankYouPage() {
  const [showWhatsappOnLeft, setShowWhatsappOnLeft] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    if (navigator) {
      console.log(navigator.userAgent, "useragent");
      if (
        navigator.userAgent.toLowerCase().includes("iphone") ||
        navigator.userAgent.toLowerCase().includes("android")
      ) {
        setShowWhatsappOnLeft(false);
      } else {
        setShowWhatsappOnLeft(true);
      }
    }
  }, []);

  // AOS.init();
  useEffect(() => {
    Aos.init();

    let redirectTimer = setTimeout(() => {
      navigate(-1)
    }, 3000)

    return () => {
      clearTimeout(redirectTimer)
    }

  }, []);



  return (
    <div className="contact-form-bg-white main-banner-sec1 fluid-padding">
      <header className="main-header1 fluid-padding">
        <Container fluid>
          <Row className="a-i-c">
            <Col xl={3} lg={3} md={3} xs={5}>
              <div className="main-logo1">
                <a href="https://ebslon.com/" target="_blank">
                  <img src={Logoimgblack} alt="" className="img-fluid" />
                </a>
              </div>
            </Col>
            <Col xl={9} lg={9} md={9} xs={7}>
              <div className="header-contact-area">
                <Link to="/ContactForm" className="req-quote-buttn1">
                  Get Quote
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="thankyoupage-sec">
        <Container fluid>
          <Row>
            <Col xl={10} className="mx-auto">
              <div
                className="thankyoupage-content"
                data-aos="zoom-in-up"
                data-aos-easing="linear"
                data-aos-duration="1000"
              >
                <img src={checkgif} className="img-fluid" alt="" />
                <h1 className="main-heading">Thanks for submitting!</h1>
                <p className="desc">
                  By submitting this form, I give consent to search to contact
                  me via call and email.
                </p>
                <p className="t-center-mt-50">
                  <Link to="/" className="get-home-buttn">
                    Get Home
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* copyright-area */}
      <div className="copyright-area">
        <Col xl={12} className="mx-auto">
          <div className="copyright-text">
            Copyright © 2023, All Right Reserved, Designed & Developed by{" "}
            <a href="https://ebslon.com/" target="_blank">
              Ebslon Infotech
            </a>
          </div>
        </Col>
      </div>

      {/* whatsapp-buttn */}

      <div
        className={`whatsapp-buttn-box ${showWhatsappOnLeft && "whatsapp-buttn-box-left"
          }`}
      >
        <a
          href="https://api.whatsapp.com/send?phone=9999634388"
          target="_blank"
        >
          {" "}
          <img
            src={whatsappicon}
            alt="whatsappicn"
            width={60}
            height={60}
          ></img>
        </a>
      </div>
    </div>
  );
}

export default ThankYouPage;
