import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import { images } from './Utility/Images'
import { BsCheckCircleFill } from 'react-icons/bs'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay} from 'swiper/modules';

function PlywoodBazarCaseStudy() {

  AOS.init();

  return (
    <>

    
    {/* breadcrumb-for-caseStudy */}
    
    <section className='breadcrumb-area'>
      <Container fluid>
        <Row>
          <Col xl={12}>
          <div className="breadcrumb-casestudy-box">
  <ul className="breadcrumb-casestudy-list">
    <li><Link to="/">Home </Link> <span>/</span> </li>
    <li><Link to="/case-studies">Case Study</Link> <span>/</span> </li>
    <li><Link to="/PlywoodBazarCaseStudy">Plywood Bazar</Link> </li>
  </ul>

 
</div>
          </Col>
   
        </Row>
      </Container>
    </section>

{/* plywood-bazar-banner-sec */}

<section className="plywood-bazar-banner-sec">
<Container>
<Row className='j-c-sb'>

<Col xl={4} md={6} className='plywood-bazar-banner-content' data-aos="fade-up"
     data-aos-duration="1000">
 <div className="logo-img">
 <img src={images.plywoodbazarmainlogo} alt="" className='img-fluid' />
 </div>

<h4 className='main-heading'>
Case Study for Plywood Bazar
</h4>

<p className="desc">
<span className='sub-head'> Plywood Bazar : </span> Premium plywood for all your construction and woodworking needs - A Case Study
</p>

<div className="plywood-bazar-intro-list-box">
<ul className="plywood-bazar-intro-list">
<li> <span className='sub-head'> Product : </span>Plywood Bazar</li>
<li> <span className='sub-head'> Industry : </span> Ecommerce</li>
<li> <span className='sub-head'> Services : </span> UI/UX Design, App Development</li>
<li> <span className='sub-head'> Technology Stack : </span> React Native, Node.js</li>
</ul>   
</div>

<p className='mt-30'><Link className='transform-button'>Contact Us</Link> </p>  



</Col>

<Col xl={8} md={6} className="plywood-bazar-banner-img" data-aos="fade-down"
     data-aos-duration="2000">

    <img src={images.plywoodbazarbannerimg} alt="" className='img-fluid' />

</Col>

</Row>
</Container>
</section>


{/* plywood-bazar-about-us-sec */}
<section className="plywood-bazar-about-us py-40">
  <Container>
    <Row className='py-3'>

    <Col xl={6} lg={6} md={6}>
        <div className="about-us-img"  data-aos="fade-down"
     data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
          <img src={images.plywoodbazaraboutusimg} alt="" className='img-fluid' />
        </div>
      </Col>

      <Col xl={6} lg={6} md={6}>
        <div className="about-content-box">
        <h6 className='sub-heading'>About</h6>
<h2 className='main-heading'>Project Highlights</h2>
<div className="about-content-list-box">
<ul className="about-content-list">
<li><BsCheckCircleFill className='circle-check'/> <h5>User-Centric Design </h5>  A comprehensive platform for seamless user interactions and an enhanced plywood purchasing experience.</li>
<li> <BsCheckCircleFill className='circle-check'/> <h5>Streamlined Processes </h5>Simplifies business operations for manufacturers, distributors, and dealers, and includes a support ticket system for timely issue resolution. </li>
<li> <BsCheckCircleFill className='circle-check'/> <h5>Effective Administration </h5> Provides total platform control with user and product management, and a secure document verification process. </li>
</ul>
</div>
        </div>
      </Col>

    </Row>
  </Container>
  <div className="bg-shape-1">
<img src={images.plywoodbazaraboutusvs1} alt="" className='img-fluid' />
  </div>
  <div className="bg-shape-2">
<img src={images.plywoodbazaraboutusvs2} alt="" className='img-fluid' />
  </div>
  <div className="bg-shape-3">
<img src={images.plywoodbazaraboutusvs3} alt="" className='img-fluid' />
  </div>
</section>



{/* our-client-requirement */}
<section className="our-client-requirement py-40">
<Container>
  <Row className='py-3'>
    <Col xl={6} lg={6} md={6}>
<div className="about-client-area">
<h3 className='main-head'>Our Client</h3>
<p className="desc"> Our client, Plywood Bazzar, is a pioneer in the plywood industry based in Mumbai, India. With a rich history spanning over two decades, they have established a strong reputation for their high-quality products and customer-focused service. Recognizing the growing need for digital transformation in their industry, Plywood Bazzar took the initiative to innovate and disrupt the traditional market norms. The result is an all-encompassing online marketplace that seamlessly connects manufacturers, distributors, dealers, and end-users, enhancing business operations and user experiences. Plywood Bazzar's vision and commitment to evolve and embrace modern solutions truly sets them apart as leaders in their field. Their ongoing dedication to incorporating advanced features into their platform demonstrates their proactive approach to staying ahead of the curve, while continuously meeting the evolving needs of their stakeholders. </p>
<div className="client-hand-shake">
  <img src={images.plywoodbazarhandshake} alt="" className='img-fluid' />
</div>
</div>
    </Col>
    <Col xl={6} lg={6} md={6}>
<div className="client-req-area">
<h3 className='main-head'>Client Requirement</h3>
<ul className="client-req-list">
  <li>Plywood Bazzar required a comprehensive online marketplace to seamlessly connect manufacturers, distributors, dealers, and end-users in the plywood industry.  </li>
  <li>They needed a platform that would streamline business operations, enhancing the overall user experience with distinct panels for each stakeholder. </li>
  <li>The platform also needed a robust admin panel for effective management and control, ensuring the smooth functioning and integrity of the marketplace.</li>
</ul>
</div>
    </Col>
  </Row>
  </Container>
</section>

{/* solution-given-sec */}
<section className="solution-given-sec py-40">
  <Container>
    <Row className='py-3 j-c-sb'>
      <Col xl={6} lg={6}>
<div className="solution-given-content">
<h6 className='sub-heading'>Solution</h6>
<h4 className='main-heading'>Proposed Solution to fix operational process</h4>
<p className="desc">To streamline the operational process, the proposed solution is to introduce Plywood Bazzar, a comprehensive online marketplace. This platform addresses the client's needs by offering distinct user interfaces for different stakeholders, including manufacturers, distributors, dealers, approvers, and end-users. Each panel is designed to cater to the specific needs of its user group, thus simplifying the operational process. In addition, a robust admin panel has been integrated into the system for seamless control and management of the entire platform. Through this approach, Plywood Bazzar effectively digitizes the traditionally complex processes of the plywood industry, ensuring more efficient operations and an improved user experience. </p>

<div className="solution-given-list-box">
<h6 className='sub-heading'>WE CHOOSE THIS SOLUTION BECAUSE OF THE FOLLOWING REASONS:</h6>
  <ul className="solution-given-list">
<li> <h5>Comprehensive and User-Centric</h5> This solution provides a comprehensive, one-stop platform that caters to the unique needs of all stakeholders. By offering distinct panels for manufacturers, distributors, dealers, approvers, and end-users, it ensures a tailored, user-centric experience.</li>
<li>  <h5>Operational Efficiency</h5>  Plywood Bazzar streamlines traditionally complex business processes, making them more efficient. It reduces manual intervention, improves communication between stakeholders, and expedites the transaction process.</li>
<li> <h5>Control and Management</h5>  The robust admin panel provides complete control and management of the platform. This ensures platform integrity, seamless operations, and the ability to swiftly address and resolve user issues. </li>
  </ul>
</div>
</div>
      </Col>
      <Col xl={5} lg={5}>
<div className="solution-given-img">
<img src={images.plywoodsolutionimg} alt="plywoodsolution" className='img-fluid' />
</div>
      </Col>
    </Row>
  </Container>
</section>

{/* plywoodbazar-features-sec */}

<section className="plywoodbazar-features-sec py-40">
  <Container>
   
    <Row className='j-c-sb py-3'>
      <Col xl={5} lg={5}>
        <div className="plywoodbazar-features-content">
<div className="plywoodbazar-features-head">
  <h6 className='sub-heading'>KEY FEATURES</h6>
  <h2 className='main-heading'>Features</h2>
</div>


<div className="key-features-list-box">
<ul className="key-features-list">
    <li>
      <h5 className='heading'>User Management </h5> 
       <p className='desc'>The platform offers comprehensive user management, allowing admins to create, update, delete, and manage user profiles for manufacturers, distributors, dealers, approvers, and end-users.</p>
       </li>
    <li>
      <h5 className='heading'>Document Verification</h5> 
       <p className='desc'>It includes a document verification process for manufacturers, distributors, and dealers, ensuring the credibility of all business stakeholders on the platform.</p>
       </li>
    <li>
      <h5 className='heading'>Product Management</h5> 
       <p className='desc'>Manufacturers, distributors, and dealers can manage their product listings, offering details such as images, videos, description, price, etc.</p>
       </li>
    <li>
      <h5 className='heading'>Search and Compare</h5> 
       <p className='desc'>The platform allows end-users to search for products and compare prices from different sellers, providing them with the best available options.</p>
       </li>
    <li>
      <h5 className='heading'>Subscription Plans</h5> 
       <p className='desc'>It provides subscription plans for businesses, allowing them to receive leads, run flash sales, and post advertisements, enhancing their business opportunities.</p>
       </li>
    <li>
      <h5 className='heading'>Ratings and Reviews</h5> 
       <p className='desc'>Users can rate and review manufacturers, distributors, and dealers, promoting transparency and accountability.</p>
       </li>
    <li>
      <h5 className='heading'>Support Tickets</h5> 
       <p className='desc'>The platform includes a support ticket system where users can raise any issues they face, ensuring a swift resolution and improving user experience.</p>
       </li>
    <li>
      <h5 className='heading'>Flash Sales and Advertisements</h5> 
       <p className='desc'>Businesses have the option to run flash sales and post advertisements depending on their subscription plan, helping them increase visibility and sales.</p>
       </li>
  </ul>
</div>


        </div>
      </Col>
      <Col xl={6} lg={6}>
        <div className="plywoodbazar-features-img">
          <img src={images.plywoodbazarfeaturesimg} alt="" className='img-fluid' />
        </div>
      </Col>
    </Row>
    
  </Container>
</section>

{/* our-work-screens-sec */}

<section className="our-work-screens py-40">
  <Container>
    <Row>
      <Col xl={4} lg={6}>
        <div className="our-work-screens-main-head">
          <h2 className='main-heading'>User Journey 
           <span> Detailed Screens</span></h2>
        </div>
      </Col>
    </Row>
  </Container>
</section>




{/* plywoodbazar-result-sec */}
<section className="plywoodbazar-result-sec py-40">
  <Container fluid>
    <Row className='pb-3'>
      <Col xl={8} className='mx-auto'>
<div className="plywoodbazar-result-main-head" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <h3 className='main-heading'>The Result</h3>
  <p className="desc">The client was very happy with the website and timely delivery of project. We have been working with him for SEO and online marketing of his website.</p>
</div>
      </Col>
    </Row>
    <Row className='py-4'>
    <Swiper
        slidesPerView={3}
        spaceBetween={30}
        centeredSlides={true}
        speed={900}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },

          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        className="mySwiper"
        
      >
        <SwiperSlide>  
<div className="plywoodbazar-result-card">
<img src={images.plywoodbazarresultslide1} alt="" className='img-fluid' />
</div>
          </SwiperSlide>
        <SwiperSlide>  
<div className="plywoodbazar-result-card">
<img src={images.plywoodbazarresultslide1} alt="" className='img-fluid' />
</div>
          </SwiperSlide>
        <SwiperSlide>  
<div className="plywoodbazar-result-card">
<img src={images.plywoodbazarresultslide1} alt="" className='img-fluid' />
</div>
          </SwiperSlide>
        <SwiperSlide>  
<div className="plywoodbazar-result-card">
<img src={images.plywoodbazarresultslide1} alt="" className='img-fluid' />
</div>
          </SwiperSlide>
        <SwiperSlide>  
<div className="plywoodbazar-result-card">
<img src={images.plywoodbazarresultslide1} alt="" className='img-fluid' />
</div>
          </SwiperSlide>
        <SwiperSlide>  
<div className="plywoodbazar-result-card">
<img src={images.plywoodbazarresultslide1} alt="" className='img-fluid' />
</div>
          </SwiperSlide>
        <SwiperSlide>  
<div className="plywoodbazar-result-card">
<img src={images.plywoodbazarresultslide1} alt="" className='img-fluid' />
</div>
          </SwiperSlide>
        
      </Swiper>
    </Row>
  </Container>
</section>

    </>
  )
}

export default PlywoodBazarCaseStudy