import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  let location = useLocation();

  const scrollHAndler = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollHAndler();
  }, [location]);

  // console.log(location, "location")
}

export default ScrollToTop;
