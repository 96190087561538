import Modal from "react-bootstrap/Modal";
// import { images } from './Utility/Images'
import dotshape from "../../assets/images/EbslonLandingPage/images/ecommerce/dot.svg";
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="popup-modal"
    >
      <Modal.Header closeButton>
        <img src={dotshape} alt="" className="img-fluid" />
      </Modal.Header>
      <Modal.Body>
        <h5 className="content">
          Dream big, sell bigger! Turn your ecommerce idea into reality{" "}
        </h5>
        <button className="call-us-buttn">Request Quote</button>
      </Modal.Body>
      <Modal.Footer>
        <img src={dotshape} alt="" className="img-fluid" />
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
