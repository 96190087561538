import { apiUrl } from "./url.service";
import axios from 'axios';

/// contacts api
export const postContactApi = (query) => {
  return axios.post(`${apiUrl}/contacts/contact-submit`, query);
}

/// service contact api
export const postServiceContApi = (query) => {
  return axios.post(`${apiUrl}/service-contact/service-submit`, query);
}


/// appointment api

export const PostAppointmentApi = (query) => {
  return axios.post(`${apiUrl}/appointment/appointment-submit`, query);
}

export const BrochureDownloadApi = (formData) => {
  return axios.post(`${apiUrl}/enquiry/brochure-download-enquiry`, formData);
}

