import React from "react";
import whatsappicon from "../../assets/images/whatappimg.webp";
import { useEffect } from "react";
import { useState } from "react";
function WhatsappButtn() {
  const [showWhatsappOnLeft, setShowWhatsappOnLeft] = useState(false);

  useEffect(() => {
    if (navigator) {
      console.log(navigator.userAgent, "useragent");
      if (
        navigator.userAgent.toLowerCase().includes("iphone") ||
        navigator.userAgent.toLowerCase().includes("android")
      ) {
        setShowWhatsappOnLeft(false);
      } else {
        setShowWhatsappOnLeft(true);
      }
    }
  }, []);
  return (
    <>
      <div
        className={`whatsapp-buttn-box ${
          showWhatsappOnLeft && "whatsapp-buttn-box-left"
        }`}
      >
        <a
          href="https://api.whatsapp.com/send?phone=919999634388"
          target="_blank"
        >
          <img
            src={whatsappicon}
            alt="whatsappicn"
            width={50}
            height={50}
          ></img>
        </a>
      </div>
    </>
  );
}

export default WhatsappButtn;
