import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { images } from './Utility/Images'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";
function TurningpointCaseStudy() {
  return (
    <>
 {/* breadcrumb-for-caseStudy */}

 <section className="breadcrumb-area">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="breadcrumb-casestudy-box">
                <ul className="breadcrumb-casestudy-list">
                  <li>
                    <Link to="/">Home </Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/case-studies">Case Study</Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/TurningpointCaseStudy">Turning Point</Link>{" "}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

  {/* turning-point case-study-banner-sec */}

  <section className="turning-point-banner-sec">
       <Container>
        <Row>

            <Col xl={4} md={6} className='turning-point-banner-content'>
             
              <img src={images.Turningpointlogo} alt="" className='img-fluid' />
<h4 className='main-heading'>
Case Study for Turning Point
</h4>

<p className="desc">
Turning Point : App Development for a Carpenter-Customer Interactive Platform - A Case Study
</p>

<div className="turning-point-intro-list-box">
<ul className="turning-point-intro-list">
           <li> Product: Furniture</li>
<li>Industry: Ecommerce</li>
<li>Services: UI/UX Design, App Development</li>
<li>Technology Stack: React Native, Node.js</li>
           </ul>   
</div>

          <p className='mt-30'><Link className='transform-button'>Contact Us</Link> </p>  
            

           
            </Col>

            <Col xl={8} md={6} className="turning-point-banner-img">
            
                <img src={images.Turningpointbannerimg} alt="" className='img-fluid' />
            
            </Col>

            </Row>
       
    </Container>

    </section>

    {/* about-client-sec */}

<section className="turning-point-about-client-sec py-40">

<Container>

<Row className='py-3'>

<Col xl={6} md={6}>
<div className="turning-point-about-client-img">
  <img src={images.Turningpointintroimg} alt="" className='img-fluid' />
</div>
</Col>

  <Col xl={6} md={6} className='turning-point-about-client-main-head'>
    <div className="turning-point-about-client-content-box">
    <h3 className='main-heading1'>Introduction </h3>
      <p className="desc">
      In an ambitious undertaking, the project aimed to create a tech-enabled bridge between carpenters and customers. The primary objective was to digitally empower local carpenters, granting them enhanced visibility and avenues to grow their business. Simultaneously, the platform was envisioned to elevate the customer experience by introducing mechanisms like reward points, coupons, and lucky drawsight.
       
        </p>
     
    </div>
  </Col>
 
</Row>


</Container>
</section>


{/* project-highlights */}


<section className="turning-point-project-highlights-sec py-40">
<Container>
  <Row>
     <Col xl={10} className='turning-point-project-highlights-head mx-auto'>
  <h3 className='main-heading'>  Project Highlights </h3>

</Col> 

  </Row>
  <Row className='py-3'>
<Col xl={12}>
<div className="project-highlights-content-area">
  <Row>

    <Col xl={3} lg={3} md={6}>
      <div className="project-highlights-card">
<div className="turning-point-project-highlights-card-content">
  <div className="number-area">
01
  </div>
  <div className="content-txt">
    <h5>Interactive Customer App</h5>
    <p className="desc">Tailored for both Android and iOS platforms to ensure widespread reach and user accessibility.</p>
  </div>
</div>
      </div>
    </Col>

    <Col xl={3} lg={3} md={6}>
      <div className="project-highlights-card-style1">
<div className="project-highlights-card-content">
  <div className="number-area">
02
  </div>
  <div className="content-txt">
    <h5>Super Admin Control</h5>
    <p className="desc">A meticulously designed web panel for efficient management and seamless operations.
    </p>
  </div>
</div>
      </div>
    </Col>

    <Col xl={3} lg={3} md={6} className='mt-20'>
      <div className="project-highlights-card">
<div className="turning-point-project-highlights-card-content">
  <div className="number-area">
03
  </div>
  <div className="content-txt">
    <h5>OTP & Password Recovery</h5>
    <p className="desc">Integrated with an SMS gateway, this feature was implemented to bolster security and streamline user verification.
    </p>
  </div>
</div>
      </div>
    </Col>

    <Col xl={3} lg={3} md={6} className='mt-20'>
      <div className="project-highlights-card-style1">
<div className="project-highlights-card-content">
  <div className="number-area">
04
  </div>
  <div className="content-txt">
    <h5>Lucky Draw Mechanism</h5>
    <p className="desc">A distinguishing feature, crafted to allow customers to partake in lucky draws using the points amassed from their purchases.
    </p>
  </div>
</div>
      </div>
    </Col>


  </Row>
</div>



</Col>
  </Row>

</Container>
</section>



 {/* features-sec */}

 <section className="turning-point-features-sec py-40">
        <div className="overlay-turning-point-features"></div>
        <div className="position-relative-z-index-1">
          <Container>
            <Row className="pb-3">
              <Col xl={10} className="mx-auto">
                <div className="features-sec-main-head">
                  <h2 className="main-heading">Features</h2>
                </div>
              </Col>
            </Row>

            <Row className="pt-3">
              <Col xl={4} lg={4} className="mt-3">
                <div className="customer-app-features-content">
                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>1</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Profile Management : </h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                              Allows users, especially carpenters, to cultivate their profiles, adding details, showcasing work, rewards, and mapping their purchase history.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>2</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>In-App Navigation : </h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                              Enabled by Google Maps, this feature aids customers in effortlessly locating carpenters or affiliated stores.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>3</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Notifications & Alerts :</h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                              Features in-app alerts and a facility for group SMS, ensuring users are perennially in the loop.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={4} lg={4} className="mt-3">
                <div className="customer-app-features-img">
                  <img
                    src={images.Turningpointfeaturesimg}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>

              <Col xl={4} lg={4} className="mt-3">
                <div className="customer-app-features-content">
               
                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>4</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Lucky Draw Access :</h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                              A coveted reward system where purchase-derived points can be expended by customers for lucky draws.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>5</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Dashboard :</h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                              A data-rich dashboard offering insights into user metrics, purchase patterns, and much more.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>6</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Banner Display :</h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                              Engineered to exhibit various promotional offers, engaging videos, and attention-grabbing reels.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>

         
          </Container>
        </div>
      </section>


{/* turning-point-challenges-and solutions */}


  <section className="turning-point-challenges-and-solutions-sec py-40">
        <Container>
          <Row className="pb-3">
            <Col xl={10} className="mx-auto">
              <div className="challenges-and-solutions-main-head">
                <h2 className="main-heading"> Challenges & Solutions </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={4} lg={4}>
              <div className="challenges-sec">
                {/* <h4>Challenges and Solutions :</h4> */}
                <ul className="challenges-content-list">
                  <li>
                    {/* <FaCheck /> */}
                     <h6>Real-time Location Tracking</h6>
                    <p className="desc">
                 <span className="challenges-heading">Challenge : </span>  Perfecting the real-time capture of the carpenter's location at app launch.
                    </p>
                    <p className="desc">
                 <span className='solutions-heading'>  Solution : </span> Deep integration with the Google Maps API to ensure pinpoint location accuracy.
                    </p>
                  </li>
              
                  <li>
                    {/* <FaCheck />  */}
                    <h6>Security Paradigms</h6>
                    <p className="desc">
                 <span className="challenges-heading">Challenge : </span>  Safeguarding user data, especially during critical processes like signup and login.
                    </p>
                    <p className="desc">
                 <span className='solutions-heading'>  Solution : </span> The deployment of OTP-based verification via an SMS gateway and the use of SSL certificates ensured watertight security.</p>
                  </li>
                  <li>
                    {/* <FaCheck /> */}
                     <h6>User Engagement</h6>
                    <p className="desc">
                 <span className="challenges-heading">Challenge : </span>  Sustaining engagement levels across both carpenter and customer user bases.
                    </p>
                    <p className="desc">
                 <span className='solutions-heading'>  Solution : </span> The induction of a rewarding system, lucky draws, and proactive notifications to maintain user interest.ensured watertight security.</p>
                  </li>
           
                </ul>
              </div>
            </Col>

            <Col xl={4} lg={4}>
              <div className="challenges-solutions-img">
                <img
                  src={images.Turningpointchallengessolutionsimg}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>

            <Col xl={4} lg={4}>
              <div className="challenges-sec">
                {/* <h4>Solutions :</h4> */}
                <ul className="challenges-content-list">
                <li>
                    {/* <FaCheck />  */}
                    <h6>Hardware Compatibility</h6>
                    <p className="desc">
                 <span className="challenges-heading">Challenge : </span> Guaranteeing app harmony with diverse hardware setups for testing and QR code outputs.
                    </p>
                    <p className="desc">
                 <span className='solutions-heading'>  Solution : </span> Direct collaboration with clients for hardware facilitation, allowing both in-house and remote testing scenarios.
                    </p>
                  </li>
                <li>
                    {/* <FaCheck />  */}
                    <h6>Coupon Dynamics</h6>
                    <p className="desc">
                 <span className="challenges-heading">Challenge : </span> Systematic monitoring and management of coupon lineage and stock.
                    </p>
                    <p className="desc">
                 <span className='solutions-heading'>  Solution : </span> The introduction of an exhaustive dashboard in the Admin panel for detailed oversight.
                    </p>
                  </li>
                <li>
                    {/* <FaCheck />  */}
                    <h6>User Experience</h6>
                    <p className="desc">
                 <span className="challenges-heading">Challenge : </span>Crafting an intuitive interface adaptable to a wide range of users.
                    </p>
                    <p className="desc">
                 <span className='solutions-heading'>  Solution : </span>A dedicated UI/UX design phase, which took into consideration varied user behaviors and navigation preferences.
                    </p>
                  </li>
              
              
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* visual-designs-sec */}

      <section className="turning-point-visual-designs-sec">
        <Container>
          <Row className="pb-2">
            <Col xl={10} className="mx-auto">
              <div className="turning-point-visual-designs-main-head">
                <h2 className="main-heading"> Visual Design </h2>
                {/* <p className="desc">
                The customized eCommerce platform developed successfully tackled the multifaceted needs of a diverse user base. By addressing unique challenges with innovative solutions, the application not only streamlined operations but also fostered trust and enhanced user experience. With its dynamic pricing, efficient search functionality, and role-specific customization, the platform stands as a testament to the power of tailored application development.
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <div className="turning-point-visual-designs-wrapper">
            <Row className="pt-3">
              <Col xl={12}>
                <Swiper
                  slidesPerView={4}
                  spaceBetween={20}
                  speed={900}
                  loop={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },

                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  className="mySwiper py-3"
                >
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide1}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide2}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide3}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide4}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide5}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide6}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide7}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide8}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide9}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide10}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide11}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide12}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide13}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide14}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="turning-point-visual-designs-slide">
                      <img
                        src={images.Turningpointportfolioslide15}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

    {/* conclusions */}

<section className="turning-point-conclusion-sec py-40">
<Container>
<Row className='py-3'>
     
      <Col xl={10} className='turning-point-conclusion-sec-head mx-auto' >
      
      <div className="turning-point-conclusion-content-box">
      <h3 className='main-heading'>Conclusion  </h3>
          <p className="desc">The Carpenter-Customer Interactive Platform is an embodiment of technological innovation paired with ground-level industry insights. By catering to the unique needs of both carpenters and customers, the platform has emerged as a beacon of digital transformation. The resulting ecosystem not only fortifies local artisans with digital tools but also offers customers a blend of convenience and rewards, setting the stage for mutual growth and satisfaction.</p>
         </div>
      </Col>
    </Row>
</Container>
</section>

    </>
  )
}

export default TurningpointCaseStudy