import React, { lazy } from 'react'
import "./NewLandingPage.css";
// import styles from './styles.module.scss'
import HomeTechnologies from './LandingPageComponents/HomeTechnologies/HomeTechnologies';
import MainBanner from './LandingPageComponents/MainBanner/MainBanner';
import Loader from '../Loader';
import { Suspense } from 'react';
import LandingHeader from './LandingPageComponents/LandingHeader/LandingHeader';
const OurServices = lazy(()=>import("./LandingPageComponents/OurServices/OurServices"));
const LandingPageTech = lazy(()=>import("./LandingPageComponents/LandingPageTechnologies/LandingPageTech"));
const WhyChooseLanding = lazy(()=>import("./LandingPageComponents/WhyChooseLanding/WhyChooseLanding"));
// const ContactusBanner = lazy(()=>import("./LandingPageComponents/ContactusBanner/ContactusBanner"));
const EngagementModels = lazy(()=>import("./LandingPageComponents/EngagementModels/EngagementModels"));
const LandingFAQ = lazy(()=>import("./LandingPageComponents/LandingFAQ/LandingFAQ"));
const Testimonials = lazy(()=>import("./LandingPageComponents/Testimonials/Testimonials"));
const OurWork = lazy(()=>import("./LandingPageComponents/OurWork/OurWork"));
const LandingFooterForm = lazy(()=>import("./LandingPageComponents/LandingFooterForm/LandingFooterForm"));



export default function EbslonNewLandingPage() {
  return (
    <>
  
<LandingHeader/>
      <MainBanner/>

       <HomeTechnologies/>

       <Suspense fallback={<Loader />}>
    
<OurServices/>
</Suspense>

<Suspense fallback={<Loader />}>
   <LandingPageTech/>
   </Suspense>


    
   <Suspense fallback={<Loader />}>
    <WhyChooseLanding/>
    </Suspense>
      

    <Suspense fallback={<Loader />}>
      <EngagementModels/>
      </Suspense>

      <Suspense fallback={<Loader />}>
      <OurWork/>
      </Suspense>

     
      <Suspense fallback={<Loader />}>
      <LandingFAQ/>
      </Suspense>

      <Suspense fallback={<Loader />}>
      <Testimonials/>
      </Suspense>

      <Suspense fallback={<Loader />}>
    <LandingFooterForm/>
    </Suspense>

     
     
    
    </>
  )
}
